.user-card {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding-left: 60px;
	padding-right: 60px;
	border-radius: 20px;
	border: 1px solid $gray-line;
	background: $white;
	
	&__person {
		width: 100%;
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid $gray-line;
		padding-right: 20px;
	}
	
	&__img-wrap {
		width: 80px;
		height: 80px;
		border-radius: 50%;
		overflow: hidden;
		z-index: 2;
		
		&._bordered {
			border: 1px solid $blue3;
		}
		
		&._default {
			background: url(../../images/user-default.svg) center center no-repeat;
			background-size: contain;
		}
	}
	
	&__img {
		z-index: 1;
	}
	
	&__name {
		word-break: break-word;
	}
	
	&__role {
		color: $gray1;
	}
	
	
	@include media_1300 {
		flex-direction: row;
		padding-left: 50px;
		padding-right: 50px;
		
		&__person {
			padding-bottom: 0;
			margin-bottom: 0;
			border-bottom: none;
			border-right: 1px solid $gray-line;
			width: calc(100% / 12 * 3.5);
		}
		
		&__menu {
			padding-left: 40px;
			width: calc(100% / 12 * 8.5);
		}
	}
	
	@include media_1000 {
		&__person {
			width: calc(100% / 12 * 4);
		}
		
		&__img-wrap {
			width: 70px;
			height: 70px;
		}
		
		&__menu {
			width: calc(100% / 12 * 8);
		}
	}
	
	@include media_700 {
		flex-direction: column;
		padding-left: 30px;
		padding-right: 30px;
		
		&__person {
			width: 100%;
			padding-bottom: 20px;
			margin-bottom: 20px;
			border-right: none;
			border-bottom: 1px solid $gray-line;
		}
		
		&__img-wrap {
			width: 50px;
			height: 50px;
		}
		
		&__menu {
			width: 100%;
			padding-left: 0;
		}
	}
}