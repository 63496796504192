@keyframes rotate-wrap {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes rotate-inner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}

@keyframes fill-stroke {
	0% {
		stroke-dashoffset: 58.8
	}
	50% {
		stroke-dashoffset: 0;
	}
	100% {
		stroke-dashoffset: -58.4
	}
}

[class*='_loading-'] {
	.spinner {
		opacity: 1;
		
		svg {
			.svg-circle-wrap {
				animation-name: rotate-wrap;
			}
			
			.svg-circle {
				animation-name: fill-stroke, rotate-inner;
			}
		}
	}
}

.spinner {
	$size: 60;
	$animationDurationMs: 1500;
	@include absolute(20px, auto, auto, 20px);
	display: block;
	width: #{$size + px};
	height: #{$size + px};
	transition: $default-transition;
	pointer-events: none;
	opacity: 0;
	z-index: 10;
	
	&::before {
		content: '';
		display: block;
		@include absolute;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		border: #{($size / 28) * 3 + px} solid rgba(255,255,255,.5);
		z-index: 1;
		border-radius: 50%;
	}

	svg {
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		z-index: 2;

		.svg-circle-wrap {
			stroke-width: 3px;
			transform-origin: 50% 50%;
			animation-duration: #{(360 * $animationDurationMs) / (360 - $size) + ms};
			animation-iteration-count: infinite;
			animation-timing-function: linear;
		}
		
		.svg-circle {
			transform-origin: 50% 50%;
			stroke-dasharray: 58.9;
			stroke-dashoffset: 58.9;
			stroke: $fuchsia-hover;
			animation-duration: #{$animationDurationMs + ms}, #{$animationDurationMs*4 + ms};
			animation-iteration-count: infinite, infinite;
			animation-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1), steps(4);
			animation-play-state: running, running;
			animation-fill-mode: forwards;
		}
	}
}
