.info-cards-wrap {
	$r: &;
	position: relative;
	width: 100%;
	
	&__list {
		position: relative;
		@include stretch-slides;
		
		&:not(.slick-initialized) {
			width: 100%;
			column-count: 2;
			column-gap: 20px;
			margin-bottom: -20px;
		}
		
		&.slick-initialized {
			width: calc(100% + 20px);
			left: -10px;
			
			.info-card {
				margin-left: 10px;
				margin-right: 10px;
				margin-bottom: 0;
			}
		}
	}
	
	@include media_700 {
		#{$r}__list {
			@include slider-preload($r, false, 'info-card')
		}
	}
}

.info-card {
	width: 100%;
	border: 1px solid $gray-line;
	border-radius: 30px;
	break-inside: avoid;
	margin-bottom: 20px;
	padding-left: 50px;
	padding-right: 50px;
	@extend .pt50;
	@extend .pb20;
	
	&__title {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		border-bottom: 1px solid $gray-line;
		
		&::before {
			font-size: 40px;
			margin-right: 20px;
		}
	}
	
	&__row {
		border-bottom: 1px solid $gray-line;
		
		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: none;
		}
	}
	
	&__description {
		border-top: 1px solid $gray-line;
		padding-bottom: 10px;
	}
	
	&__subtitle,
	&__rating,
	&__val {
		color: $dark1;
	}
	
	&__label {
		color: $gray1;
	}
	
	&__details {
		display: inline-block;
	}
	
	&__status {
		&._green {
			color: $green;
		}
		
		&._orange,
		&._red {
			color: $red;
		}

		&._yellow {
			color: $yellow;
		}
	}
	
	&__subtitle + &__table {
		margin-top: 10px;
	}
	
	&__label + &__table {
		margin-top: 15px;
	}
	
	&__status + &__val {
		margin-top: 5px;
	}
	
	&__rating + &__details {
		margin-top: 7px;
	}
	
	&__table {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
	
	&__col {
		&_left {
			text-align: left;
		}
		
		&_right {
			text-align: right;
		}
	}
	
	@include media_1300 {
		padding-left: 40px;
		padding-right: 40px;
	}
	
	@include media_700 {
		padding-left: 30px;
		padding-right: 30px;
		
		&__title {
			&::before {
				font-size: 30px;
				margin-right: 15px;
			}
		}
	}
}
