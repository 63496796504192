.slide-menu {
	$r: &;
	position: relative;
	display: block;
	width: 100%;
	height: 30px;
	color: black;
	margin-right: auto;
	padding-top: 8px;
	box-sizing: content-box;
	
	&::before,
	&::after {
		content: '';
		display: block;
		height: 100%;
		z-index: 3;
		transition: $default-transition;
		pointer-events: none;
		width: 58px;
	}
	
	&::before {
		@include absolute(0, auto, auto);
		background: linear-gradient(90deg, $white 50%, rgba(255, 255, 255, 0) 100%);
	}
	
	&::after {
		@include absolute(0,0,0,auto);
		background: linear-gradient(270deg, $white 50%, rgba(255, 255, 255, 0) 100%);
	}
	
	&__overflow-wrap {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	
	&__list {
		@include absolute(0,auto,auto);
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		max-width: none;
		width: auto;
		z-index: 2;
		cursor: grab;
		
		&:active {
			cursor: grabbing;
		}
		
		&.ui-draggable-dragging {
			#{$r}__item {
				pointer-events: none;
			}
		}
	}
	
	&__item {
		display: block;
		white-space: nowrap;
		padding-right: 30px;
		
		&:last-child {
			padding-right: 0;
		}
	}
	
	&__link {
		padding-bottom: 11px;
		border-bottom: 2px solid transparent;
		color: $gray1;
		transition: $default-transition;
		cursor: pointer;
		
		&:hover {
			color: $gray0;
		}

		&._active {
			color: $dark1;
			border-color: $dark1;
		}
	}
	
	&__button {
		z-index: 10;
		transition: $default-transition;
		
		&:not(._init) {
			opacity: 0;
		}
		
		&_left {
			@include absolute(0, auto, auto);
		}
		
		&_right {
			@include absolute(0, 0, auto, auto);
		}
	}
	
	&._start {
		&::before {
			opacity: 0;
		}
		
		#{$r}__button_left {
			pointer-events: none;
			opacity: 0;
		}
	}
	
	&._end {
		&::after {
			opacity: 0;
		}
		
		#{$r}__button_right {
			pointer-events: none;
			opacity: 0;
		}
	}
	
	&._gray {
		&::before {
			background: linear-gradient(90deg, $gray2 50%, rgba(255, 255, 255, 0) 100%);
		}
		
		&::after {
			background: linear-gradient(270deg, $gray2 50%, rgba(255, 255, 255, 0) 100%);
		}
	}
	
	&._turquoise {
		&::before,
		&::after {
			display: none;
		}
		
		#{$r}__link {
			color: $turquoise;
			
			&:hover {
				color: $blue;
			}
			
			&._active {
				color: $dark1;
				border-color: $dark1;
			}
		}
	}
	
	@include media_700 {
		height: 25px;
		
		&__item {
			padding-right: 10px;
		}
		
		&__link {
			padding-bottom: 6px;
		}
	}
}