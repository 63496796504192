$bar-width: 24px;
$bar-height: 3px;
$bar-spacing: 6px;
$bar-color: $dark1;
$active-color: $fuchsia;

.hamburger-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	cursor: pointer;
	z-index: 2;

	&:hover & {
		&__bar {
			&::before {
				background: $active-color;
			}
		}
	}

	&__hamburger {
		width: $bar-width;
		height: $bar-height * 3 + $bar-spacing * 2;
		position: relative;
		z-index: 1;
		cursor: pointer;
		display: block;
	}

	&__bar {
		width: $bar-width;
		height: $bar-height;
		will-change: transform;
		transform-origin: 50% 50%;
		transition: transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), opacity 0s 300ms linear;

		&::before {
			content: '';
			display: block;
			position: relative;
			width: 100%;
			height: 100%;
			background: $bar-color;
			transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1), background 300ms ease;
		}

		&:nth-child(1) {
			@include absolute(0, auto, auto);
		}

		&:nth-child(2) {
			@include absolute(0, auto);
		}

		&:nth-child(3) {
			@include absolute(auto, auto, 0);
		}
	}
	
	&._active & {
		&__bar {
			transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1), opacity 0s 300ms linear;
			
			&::before {
				transition: transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), background 300ms ease;;
			}
			
			&:nth-child(1) {
				transform: translateY(($bar-height + $bar-spacing));
				
				&::before {
					transform: rotate(45deg);
				}
			}
			
			&:nth-child(2) {
				opacity: 0;
			}
			
			&:nth-child(3) {
				transform: translateY(-($bar-height + $bar-spacing));
				
				&::before {
					transform: rotate(-45deg);
				}
			}
		}
	}
}

