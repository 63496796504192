.row-buttons {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	
	&__item {
		padding: 4px 10px;
		border-radius: 5px;
		background: $gray2;
		color: $gray0;
		transition: $default-transition;
		margin-right: 5px;
		cursor: pointer;
		
		&:last-child {
			margin-right: 0;
		}
		
		&:hover {
			background: $gray3;
		}
		
		&._active {
			background: $gray0;
			color: $white;
		}
	}
}