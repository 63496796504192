._gray-dots-nav {
	& > .slick-dots {
		.dots-track {
			li {
				button {
					&::before {
						background: $gray-line;
					}
				}
			}
		}
	}
}

._absolute-dots-nav {
	& > .slick-dots {
		@include absolute(auto);
		padding-top: 0;
	}
}

.slick-dots {
	display: block;
	position: relative;
	overflow: hidden;
	margin: 20px auto -10px;
	
	.dots-track {
		@include absolute(0, auto, auto, 0);
		width: auto;
		max-width: none;
		display: flex !important;
		padding: 0;
		margin: 0;
		transition: transform .3s linear;
		
		li {
			position: relative;
			width: 17px;
			height: 27px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-grow: 0;
			flex-shrink: 0;
			flex-basis: auto;
			cursor: pointer;
			
			button {
				font-size: 0;
				letter-spacing: 0;
				line-height: 0;
				background: transparent;
				border: none;
				cursor: pointer;
				
				&::before {
					content: '';
					display: block;
					background: $white;
					border-radius: 50%;
					width: 7px;
					height: 7px;
					transition: $default-transition;
					will-change: transform;
					cursor: pointer;
				}
			}
			
			&:not(.slick-active) {
				button {
					&::before {
						transform: scale(.6);
					}
				}
			}
		}
	}
	
	&._center {
		display: flex;
		align-items: center;
		justify-content: center;
		
		.dots-track {
			position: relative;
		}
	}
	
	@include media_700 {
		margin-top: 10px;
	}
}
