.content-section {
	&:last-child {
		margin-bottom: 0;
	}
}

.text-block:not(._custom-text) {
	p, ul li, ol li, a {
		&:not(.subtitle) {
			@extend .table-text;
		}
	}
}

.text-block._body-text {
	p, ul li, ol li, a {
		&:not(.subtitle) {
			@extend .body-text;
		}
	}
}

.text-block {
	color: $gray0;
	
	.img-wrap {
		position: relative;
		display: block;
		width: calc(50% - 10px);
		height: auto;
		border-radius: 20px;
		overflow: hidden;
		z-index: 3;
		@include rh(400/590);
		
		img {
			@extend .of;
			z-index: 1;
		}
	}
	
	em {
		font-style: italic;
	}
	
	.img-gallery {
		position: relative;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;
		width: calc(100% / 10 * 12 + 20px);
		left: calc(-100% / 10 - 10px);
		@extend .pt10;
		@extend .mtr20;
		@extend .mb40;
		
		&:first-child {
			margin-top: 0;
		}
		
		&:last-child {
			margin-bottom: 0;
		}
		
		.img-wrap {
			width: calc(100% / 3 - 20px);
			@extend .mt20;
			margin-left: 10px;
			margin-right: 10px;
		}
	}
	
	.img-big {
		position: relative;
		width: calc(100% / 10 * 12 + 20px);
		left: calc(-100% / 10 - 10px);
		@extend .mt40;
		@extend .mb40;
		
		&:first-child {
			margin-top: 0;
		}
		
		&:last-child {
			margin-bottom: 0;
		}
		
		.img-wrap {
			width: calc(100% - 20px);
			height: 600px;
			margin: 0 10px;
			
			&::after {
				display: none;
			}
		}
	}
	
	.img-float-right {
		.img-wrap {
			width: calc(100% / 10 * 6 - 10px);
			margin: 0 calc(-100% / 10) 20px 50px;
			float: right;
		}
	}
	
	.img-float-left {
		.img-wrap {
			width: calc(100% / 10 * 6 - 10px);
			margin: 0 50px 20px calc(-100% / 10);
			float: left;
		}
	}
	
	h1, h2, h3, h4, h5, h6 {
		&:first-child {
			margin-top: 0;
		}
	}
	
	p {
		@extend .mb30;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	h1 {
		@extend .h1;
		@extend .mt40;
		@extend .mb50;
	}
	
	h2 {
		@extend .h2;
		@extend .mt40;
		@extend .mb40;
	}
	
	h3 {
		@extend .h3;
		@extend .mt40;
		@extend .mb20;
	}
	
	h4 {
		@extend .h4;
		@extend .mt40;
		@extend .mb20;
	}
	
	h5 {
		@extend .h5;
		@extend .mt40;
		@extend .mb20;
	}
	
	h6 {
		@extend .h6;
		@extend .mt40;
		@extend .mb20;
	}
	
	a {
		@extend .link-fuchsia;
	}
	
	ol, ul {
		padding-left: 0;
		@extend .mb30;
		
		li {
			padding-left: 0;
			
			&::before {
				margin-left: 0;
				margin-right: 0.5em;
				width: auto;
				color: #CB009B;
			}
		}
	}
	
	ul {
		li {
			&::before {
				font-weight: 700;
			}
		}
	}
	
	ol > li,
	ul > li {
		list-style-type: none;
	}
	ul > li::before {
		content: '\2022';
	}
	li::before {
		display: inline-block;
		white-space: nowrap;
	}
	ol li {
		counter-increment: list-0;
	}
	ol li:before {
		content: counter(list-0, decimal) '. ';
	}
	
	
	@include media_1300 {
		.img-gallery {
			width: calc(100% + 20px);
			left: -10px;
		}
		
		.img-big {
			width: calc(100% + 20px);
			left: -10px;
			
			.img-wrap {
				height: 400px;
			}
		}
		
		.img-float-right {
			.img-wrap {
				width: calc(50% - 10px);
				margin: 0 0 20px 50px;
			}
		}
		
		.img-float-left {
			.img-wrap {
				width: calc(50% - 10px);
				margin: 0 50px 20px 0;
			}
		}
	}
	
	@include media_1000 {
		.img-big {
			.img-wrap {
				height: 350px;
			}
		}
	}
	
	@include media_700 {
		.img-wrap {
			width: 100%;
		}
		
		.img-gallery {
			.img-wrap {
				width: calc(100% - 20px);
			}
		}
		
		.img-big {
			.img-wrap {
				height: auto;
				@include rh(400/590);
			}
		}
		
		.img-float-right {
			.img-wrap {
				width: 100%;
				margin: 0 0 20px 0;
			}
		}
		
		.img-float-left {
			.img-wrap {
				width: 100%;
				margin: 0 0 20px 0;
			}
		}
	}
}