.custom-table {
  width: 100%;
  border-collapse: collapse;

  tr td, tr th {
    padding-left: 0 !important;
  }


  thead {
    th {
      font-weight: bold;
      color: #c2c2c2;
      padding: 10px;
    }
  }


  tbody {
    td {
      padding: 10px;
    }
  }

  table {
    border: 1px solid #dee2e6;
    border-radius: 4px;
    overflow: hidden;
  }

}

.FeatureList {
  width: 100%;
  display: inline-block;
  margin-top:70px;
}

.custom-table-key-val {

  columns: 2;

  .table-block {
    display: inline-block;
  }

  .table {
    table-layout: fixed;
    width: 100%;
  }

  .table-head {
    width: 50%;
    border-bottom: unset;
    padding-bottom: 20px;
    line-height: 25px;
    color: #ACB8C0;
  }

  .table-val {
    width: 50%;
    border-bottom: unset;
    padding: 5px 5px;
    color: #374246;
  }
}
.property-table__list.FeatureList
{
  opacity: 1 !important;
  width: 100% !important;
  //flex-wrap: wrap;
  //display: flex;
  columns: 2;
}

.FeatureList .property-table__col
{
  display: block !important;
  margin-bottom: 45px;
  max-width: 100% !important;
  width: 100% !important;
  overflow: hidden;
}

/* SCSS Version */

.blur-content {
  filter: blur(8px);
  pointer-events: none; // Prevent clicks on blurred content
}

.blur-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  padding: 15px 25px;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 2; // Ensure the overlay appears on top

  a {
    color: #0056b3;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
}

.slider-section {
  position: relative;
}
