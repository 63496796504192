@media print {
	body._print {
		.wrapper {
			& > *:not(.print-popup) {
				display: none;
			}
		}
	}
	
	body._print-listing {
		.print-popup._listing {
			opacity: 1;
			left: 0;
		}
	}
	
	body._print-favorites {
		.print-popup._favorites {
			opacity: 1;
			left: 0;
		}
	}
}

.print-popup {
	width: 100%;
	max-width: 800px;
	height: auto;
	min-height: 100vh;
	color: $black;
	opacity: 0;
	@include absolute(0, auto, auto, -100vw);
	padding: 40px;
	
	&__title {
		color: $black;
	}
	
	&__top {
		position: relative;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
	}
	
	&__logo-wrap {
		position: relative;
		width: 200px;
		height: 50px;
	}
	
	&__agent-wrap {
		width: calc(100% - 200px);
		padding-left: 25px;
	}
	
	&__logo {
		display: block;
	}
	
	&__table {
		position: relative;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
	
	&__col {
		position: relative;
		width: calc(50% - 10px);
		height: 220px;
		border-radius: 20px;
		overflow: hidden;
	}
}

.agent-print {
	padding: 12px 15px;
	border: 1px solid $gray-line;
	border-radius: 20px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	
	&__photo-wrap {
		position: relative;
		width: 64px;
		height: 64px;
		border-radius: 10px;
		overflow: hidden;
		z-index: 2;
	}
	
	&__photo {
		display: block;
		z-index: 1;
	}
	
	&__info {
		width: calc(100% - 64px);
		padding-left: 10px;
	}
	
	&__contacts {
		display: flex;
		align-items: baseline;
		justify-content: flex-start;
	}
	
	&__name {
		margin-right: 10px;
	}
	
	&__tel {
		color: $black;
	}
}

.print-props-table {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	
	&__item {
		width: 25%;
		padding-right: 20px;
	}
}

.listed-by {
	padding: 20px 30px 10px;
	box-shadow: inset 0 0 0 1000px $gray4;
	border-radius: 20px;
	
	.icon-mls-min {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		justify-content: flex-end;
		
		&::before {
			font-size: 20px;
			margin-left: 5px;
		}
	}
}

@page { size: auto;  margin: 0mm; }