.tile {
	$r: &;
	
	&__row {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	&__col {
		&_left {
			width: calc(100% - 400px);
		}
		
		&_right {
			width: 400px;
		}
	}
	
	&__img-wrap {
		width: 300px;
		height: auto;
	}
	
	&__img {
		display: block;
		max-width: 100%;
		height: auto;
	}
	
	@include media_min-700 {
		&__row {
			&:nth-child(odd) {
				flex-direction: row;
				
				#{$r}__img-wrap {
					margin-left: auto;
				}
			}
			
			&:nth-child(even) {
				flex-direction: row-reverse;
				
				#{$r}__img-wrap {
					margin-right: auto;
				}
			}
		}
	}
	
	@include media_1300 {
		
		&__col {
			&_left {
				width: calc(100% - 350px);
			}
			
			&_right {
				width: 350px;
			}
		}
	}
	
	@include media_1000 {
		&__col {
			&_left {
				width: calc(100% - 250px);
			}
			
			&_right {
				width: 250px;
			}
		}
		
		&__img-wrap {
			width: 200px;
		}
	}
	
	@include media_700 {
		&__row {
			flex-direction: column-reverse;
			align-items: flex-start;
			justify-content: flex-start;
		}
		
		&__col {
			width: 100%;
		}
		
		&__img-wrap {
			width: 100%;
			height: 150px;
			margin-bottom: 30px;
		}
		
		&__img {
			width: auto;
			height: 100%;
		}
	}
}