.header {
    z-index: 90;
    display: block;
    position: relative;
    width: 100%;
    height: 100px;
    


    &__fixed-wrap {
        @include fixed(0,auto,auto);
        width: inherit;
        height: inherit;
        box-sizing: border-box;
        border: 1px solid $gray-line;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 40px;
    }

    &__logo-wrap {
        position: relative;
        margin-right: auto;
    }
    
    &__search-wrap {
        position: relative;
        margin-right: auto;
        max-width: 700px;
        padding: 0 50px;
        flex: 1 0 0;
    }

    &__control-wrap {
        position: relative;
        margin-right: 20px;
        
        &:last-child {
            margin-right: 0;
        }
        
        &_fh {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
    
            .dropdown-button {
                &__popup {
                    top: calc(100% - 20px);
                }
            }
        }
        
        &_up {
            z-index: 10;
        }
    }

    &__menu-wrap {
        @extend .menu-wrap;
        width: 250px;
        padding: 40px;
        display: flex;
        flex-direction: column;
    }
    
    &__scroll-wrap {
        height: 100%;
    }

    .hamburger-wrapper._active ~ &__menu-wrap {
        @extend .menu-wrap-active;
    }
    
    @include media_1300 {
        &__menu-wrap {
            padding: 20px;
        }
    }
    
    @include media_1000 {
        height: 80px;
        
        &__fixed-wrap {
            padding: 0 30px;
        }
    
        &__search-wrap {
            max-width: 560px;
            padding: 0 30px;
        }
        
        &__menu-wrap {
            top: calc(100% - 10px);
        }
    }
    
    @include media_700 {
        height: 50px;
        
        &__fixed-wrap {
            padding: 0 20px;
        }
    
    
        .hamburger-wrapper._active ~ &__menu-wrap {
            right: -20px;
        }
        
        &__menu-wrap {
            top: 100%;
            border-radius: 0;
            width: 100vw;
            height: calc(100vh - 50px);
            padding-right: 10px;
        }
        
        &__show-search {
            position: relative;
            margin-right: 20px;
            
            &::before,
            &::after {
                transition: $default-transition;
            }
            
            &::after {
                content: '\e923';
                @include absolute;
                width: 10px;
                height: 10px;
                font-family: $icomoon;
                font-size: 10px;
                line-height: 1;
                font-weight: 100;
                color: $white;
                opacity: 0;
            }
            
            &._active {
                &::before {
                    opacity: 0;
                }
                
                &::after {
                    opacity: 1;
                }
            }
        }
        
        &__search-wrap {
            width: calc(100% - 20px);
            height: 88px;
            max-width: none;
            padding: 0;
            flex: none;
            @include absolute(calc(100% + 10px),0,auto,-200vw);
            transform: translateY(20px);
            opacity: 0;
            transition-property: transform, opacity, left;
            transition-timing-function: ease, ease, linear;
            transition-duration: .3s, .3s, 0s;
            transition-delay: 0s, 0s, .3s;
        }
    
        &__show-search._active ~ &__search-wrap {
            left: 0;
            transform: translateY(0);
            opacity: 1;
            transition-delay: 0s, 0s, 0s;
        }
    }
}

.navbar {
    &__item {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.header-menu {
    .dropdown-button {
        width: auto;
        
        &__popup {
            top: calc(100% + 10px);
            left: 0;
            right: auto;
        }
        
        &._fh {
            height: auto;
        }
    }
    
    .btn-style {
        width: 100%;
    }
    
    @include media_700 {
        padding-bottom: 50px;
    }
}