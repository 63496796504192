$b1700: "all and (max-width: 1700px)";
$b1500: "all and (max-width: 1500px)";
$b1300: "all and (max-width: 1300px)";
$b1200: "all and (max-width: 1200px)";
$b1000: "all and (max-width: 1000px)";
$b950: "all and (max-width: 950px)";
$b900: "all and (max-width: 900px)";
$b700: "all and (max-width: 700px)";
$b600: "all and (max-width: 600px)";
$b500: "all and (max-width: 500px)";
$b450: "all and (max-width: 450px)";
$b400: "all and (max-width: 400px)";
$b350: "all and (max-width: 350px)";

$b2400_min: "all and (min-width: 2401px)";
$b2000_min: "all and (min-width: 2001px)";
$b1700_min: "all and (min-width: 1701px)";
$b1500_min: "all and (min-width: 1501px)";
$b1300_min: "all and (min-width: 1301px)";
$b1200_min: "all and (min-width: 1201px)";
$b1000_min: "all and (min-width: 1001px)";
$b950_min: "all and (min-width: 951px)";
$b900_min: "all and (min-width: 901px)";
$b700_min: "all and (min-width: 701px)";
$b500_min: "all and (min-width: 501px)";
$b450_min: "all and (min-width: 451px)";
$b400_min: "all and (min-width: 401px)";

$b1300-1000: "all and (max-width: 1300px) and (min-width: 1001px)";
$b1300-700: "all and (max-width: 1300px) and (min-width: 701px)";

@mixin media_1300-1000 {
	@media #{$b1300-1000} {
		@content;
	}
}
@mixin media_1300-700 {
	@media #{$b1300-700} {
		@content;
	}
}

@mixin media_1700 {
	@media #{$b1700} {
		@content;
	}
}
@mixin media_1500 {
	@media #{$b1500} {
		@content;
	}
}
@mixin media_1300 {
	@media #{$b1300} {
		@content;
	}
}
@mixin media_1000 {
	@media #{$b1000} {
		@content;
	}
}
@mixin media_1200 {
	@media #{$b1200} {
		@content;
	}
}
@mixin media_950 {
	@media #{$b950} {
		@content;
	}
}
@mixin media_900 {
	@media #{$b900} {
		@content;
	}
}
@mixin media_700 {
	@media #{$b700} {
		@content;
	}
}
@mixin media_600 {
	@media #{$b600} {
		@content;
	}
}
@mixin media_500 {
	@media #{$b500} {
		@content;
	}
}
@mixin media_450 {
	@media #{$b450} {
		@content;
	}
}
@mixin media_400 {
	@media #{$b400} {
		@content;
	}
}
@mixin media_350 {
	@media #{$b350} {
		@content;
	}
}

@mixin media_min-2400 {
	@media #{$b2400_min} {
		@content;
	}
}
@mixin media_min-2000 {
	@media #{$b2000_min} {
		@content;
	}
}
@mixin media_min-1700 {
	@media #{$b1700_min} {
		@content;
	}
}
@mixin media_min-1500 {
	@media #{$b1500_min} {
		@content;
	}
}
@mixin media_min-1300 {
	@media #{$b1300_min} {
		@content;
	}
}
@mixin media_min-1200 {
	@media #{$b1200_min} {
		@content;
	}
}
@mixin media_min-1000 {
	@media #{$b1000_min} {
		@content;
	}
}
@mixin media_min-950 {
	@media #{$b950_min} {
		@content;
	}
}
@mixin media_min-900 {
	@media #{$b900_min} {
		@content;
	}
}
@mixin media_min-700 {
	@media #{$b700_min} {
		@content;
	}
}
@mixin media_min-500 {
	@media #{$b500_min} {
		@content;
	}
}
@mixin media_min-450 {
	@media #{$b450_min} {
		@content;
	}
}
@mixin media_min-400 {
	@media #{$b400_min} {
		@content;
	}
}

.show-on-1700 {
	@include media_min-1700 {
		display: none !important;
	}
}

.hide-on-1700 {
	@include media_1700 {
		display: none !important;
	}
}

.show-on-1500 {
	@include media_min-1500 {
		display: none !important;
	}
}

.hide-on-1500 {
	@include media_1500 {
		display: none !important;
	}
}

.show-on-1300 {
	@include media_min-1300 {
		display: none !important;
	}
}

.hide-on-1300 {
	@include media_1300 {
		display: none !important;
	}
}

.show-on-1000 {
	@include media_min-1000 {
		display: none !important;
	}
}

.hide-on-1000 {
	@include media_1000 {
		display: none !important;
	}
}

.show-on-700 {
	@include media_min-700 {
		display: none !important;
	}
}

.hide-on-700 {
	@include media_700 {
		display: none !important;
	}
}

.show-on-500 {
	@include media_min-500 {
		display: none !important;
	}
}

.hide-on-500 {
	@include media_500 {
		display: none !important;
	}
}
