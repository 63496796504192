.intro {
	$r: &;
	position: relative;
	width: 100%;
	height: 550px;
	z-index: 10;
	
	&__gradient,
	&__bg-wrap {
		@include absolute(0,auto,auto);
		width: 100%;
		height: 100%;
		border-radius: 0 0 20px 20px;
	}
	
	&__gradient {
		z-index: 1;
	}
	
	&__bg-wrap {
		z-index: 2;
		overflow: hidden;
	}
	
	&__svg-wrap {
		@include absolute(0, auto, auto, 50%);
		@include rh(550/1920);
		width: 100%;
		min-width: 1920px;
		transform: translateX(-50%);
		
		svg {
			@include absolute;
			width: 100%;
			height: auto;
			backface-visibility: hidden;
			pointer-events: none;
			
			.animate-in-window {
				transform-box: fill-box;
			}
		}
	}
	
	&__container {
		position: relative;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		z-index: 3;
	}
	
	&__content {
	
	}
	
	&._big {
		#{$r}__svg-wrap {
			@include rh(700/1920);
		}
	}
	
	@include media_min-1300 {
		&._big {
			height: 700px;
		}
	}
	
	@include media_1300 {
		height: 500px;
		
		&__svg-wrap {
			min-width: 1750px;
		}
		
		&._big &__svg-wrap {
			min-width: 1371px;
		}
	}
	
	@include media_1000 {
	
	}
	
	@include media_700 {
		height: 480px;
		
		&__svg-wrap {
			min-width: 1680px;
		}
		
		&._big &__svg-wrap {
			min-width: 1320px;
		}
	}
}

.intro-animations-1 {
	@keyframes intro-1-boat-1-animation {
		0% {
			transform: translate3d(0, 0, 0);
			opacity: 1;
		}
		
		50% {
			transform: translate3d(725%, 0, 0);
			opacity: 1;
		}
		
		51% {
			transform: translate3d(725%, 0, 0);
			opacity: 0;
		}
		
		52% {
			transform: translate3d(-170%, 0, 0);
			opacity: 0;
		}
		
		53% {
			transform: translate3d(-170%, 0, 0);
			opacity: 1;
		}
		
		100% {
			transform: translate3d(725%, 0, 0);
			opacity: 1;
		}
	}
	@keyframes intro-1-boat-2-animation {
		0%, 100% {
			transform: translate3d(0, 0, 0);
		}
		
		20% {
			transform: translate3d(1500%, -120%, 0);
		}
		
		70% {
			transform: translate3d(-2100%, -160%, 0);
		}
	}
	@keyframes intro-1-boat-3-animation {
		0%, 100% {
			transform: translate3d(0, 0, 0);
		}
		
		10% {
			transform: translate3d(-480%, 50%, 0);
		}
		
		50% {
			transform: translate3d(-2400%, 50%, 0);
		}
		
		80% {
			transform: translate3d(-960%, 50%, 0);
		}
		
		90% {
			transform: translate3d(-480%, 0, 0);
		}
	}
	
	@keyframes intro-1-bird-1-animation {
		0%, 100% {
			transform: rotate(0deg);
			opacity: 1;
		}
		
		50% {
			transform: rotate(-50deg);
			opacity: 1;
		}
		
		51% {
			transform: rotate(-50deg);
			opacity: 0;
		}
		
		52% {
			transform: rotate(50deg);
			opacity: 0;
		}
		
		53% {
			transform: rotate(50deg);
			opacity: 1;
		}
	}
	
	@keyframes intro-1-bird-2-animation {
		0%, 100% {
			transform: translate3d(0, 0, 0) scale(1);
			opacity: 1;
		}
		
		70% {
			transform: translate3d(650%, -1080%, 0) scale(1.5);
			opacity: 1;
		}
		
		71% {
			transform: translate3d(650%, -1080%, 0) scale(1.5);
			opacity: 0;
		}
		
		72% {
			transform: translate3d(-200%, 190%, 0) scale(.5);
			opacity: 0;
		}
		
		73% {
			transform: translate3d(-200%, 190%, 0) scale(.5);
			opacity: 0;
		}
	}
	
	@keyframes intro-1-bird-3-animation {
		0%, 100% {
			transform: rotate(0deg) scale(1);
			opacity: 1;
		}

		15% {
			transform: rotate(10deg) scale(1);
			opacity: 1;
		}
		
		16% {
			transform: rotate(10deg) scale(1);
			opacity: 0;
		}
		
		17% {
			transform: rotate(-70deg) scale(.5);
			opacity: 0;
		}
		
		18% {
			transform: rotate(-70deg) scale(.5);
			opacity: 1;
		}
	}

	@keyframes intro-1-man-hand-animation {
		0%, 30%, 50%, 100% {
			transform: rotate(0deg);
		}

		15%, 75% {
			transform: rotate(-15deg);
		}
	}
	
	@keyframes intro-1-man-animation {
		0%, 50%, 100% {
			transform: skewX(0deg);
		}
		
		75% {
			transform: skewX(-2deg);
		}
	}
	
	@keyframes intro-1-woman-animation {
		0%, 50%, 100% {
			transform: skewX(0deg);
		}
		
		75% {
			transform: skewX(2deg);
		}
	}
	
	@keyframes intro-1-man-head-animation {
		0%, 50%, 100% {
			transform: rotate(0deg);
		}
		
		75% {
			transform: rotate(15deg);
		}
	}
	
	@keyframes intro-1-woman-head-animation {
		0%, 50%, 100% {
			transform: rotate(0deg);
		}
		
		75% {
			transform: rotate(-15deg);
		}
	}
	
	.svg-boat {
		animation-duration: 180s;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		
		&_1 {
			animation-name: intro-1-boat-1-animation;
		}
		&_2 {
			animation-name: intro-1-boat-2-animation;
		}
		&_3 {
			animation-name: intro-1-boat-3-animation;
		}
	}
	
	.svg-bird {
		animation-duration: 10s;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		
		&_1 {
			transform-origin: 0% -1500%;
			animation-name: intro-1-bird-1-animation;
		}
	}
	
	@include media_min-900 {
		.svg-leaves {
			transform-origin: 50% 100%;
			animation-duration: 3s;
			animation-timing-function: ease-in-out;
			animation-name: leaves-animation;
			animation-iteration-count: infinite;
			
			&_1 {
				animation-delay: .3s;
			}
		}
		
		.svg-woman,
		.svg-woman-head,
		.svg-man,
		.svg-man-head,
		.svg-man-hand {
			animation-duration: 7.5s;
		}
		
		.svg-woman {
			transform-origin: 50% 100%;
			animation-timing-function: ease-in-out;
			animation-iteration-count: infinite;
			animation-name: intro-1-woman-animation;
		}
		
		.svg-woman-head {
			transform-origin: 50% 100%;
			animation-timing-function: ease-in-out;
			animation-iteration-count: infinite;
			animation-name: intro-1-woman-head-animation;
		}
		
		.svg-man {
			transform-origin: 50% 100%;
			animation-timing-function: ease-in-out;
			animation-iteration-count: infinite;
			animation-name: intro-1-man-animation;
		}
		
		.svg-man-head {
			transform-origin: 50% 100%;
			animation-timing-function: ease-in-out;
			animation-iteration-count: infinite;
			animation-name: intro-1-man-head-animation;
		}
		
		.svg-man-hand {
			animation-timing-function: ease-in-out;
			animation-iteration-count: infinite;
			transform-origin: 10% 25%;
			animation-name: intro-1-man-hand-animation;
		}
	}
	
	@include media_min-500 {
		.svg-bird {
			&_3 {
				transform-origin: 300% 870%;
				animation-name: intro-1-bird-3-animation;
			}
		}
	}
	
	@include media_min-450 {
		.svg-bird {
			&_2 {
				transform-origin: 50% 50%;
				animation-name: intro-1-bird-2-animation;
			}
		}
	}
}

.intro-animations-2 {
	@keyframes intro-2-boat-1-animation {
		0%, 100% {
			transform: translate3d(0, 0, 0);
		}

		25% {
			transform: translate3d(5825%, 0, 0);
		}

		75% {
			transform: translate3d(-3075%, 0, 0);
		}
	}
	
	@keyframes intro-2-boat-2-animation {
		0%, 100% {
			transform: translate3d(0, 0, 0);
		}

		25% {
			transform: translate3d(-2775%, 0, 0);
		}

		75% {
			transform: translate3d(3025%, 0, 0);
		}
	}
	
	@keyframes intro-2-boat-3-animation {
		0%, 100% {
			transform: translate3d(0, 0, 0);
		}

		25% {
			transform: translate3d(-4175%, 0, 0);
		}

		75% {
			transform: translate3d(3325%, 0, 0);
		}
	}
	
	@keyframes intro-2-boat-4-animation {
		0%, 100% {
			transform: translate3d(0, 0, 0);
		}

		25% {
			transform: translate3d(5225%, 0, 0);
		}

		75% {
			transform: translate3d(-5775%, 0, 0);
		}
	}
	
	.svg-boat {
		animation-duration: 180s;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		
		&_1 {
			animation-name: intro-2-boat-1-animation;
		}
		&_2 {
			animation-name: intro-2-boat-2-animation;
		}
		&_3 {
			animation-name: intro-2-boat-3-animation;
		}
		&_4 {
			animation-name: intro-2-boat-4-animation;
		}
	 }
}

.intro-animations-3 {
	@keyframes intro-3-clouds-animation {
		0%, 100% {
			transform: translate3d(0,0,0);
		}
		
		50% {
			transform: translate3d(-15%,0,0);
		}
	}

	@keyframes intro-3-clouds-animation {
		0%, 100% {
			transform: translate3d(0,0,0);
		}
		
		50% {
			transform: translate3d(-45%,0,0);
		}
	}
	
	.svg-tree {
		animation-duration: 3s;
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite;
		animation-name: leaves-animation;
		transform-origin: 50% 100%;
		
		@for $i from 1 through 10 {
			&:nth-child(#{$i}n) {
				animation-delay: #{50*$i + ms};
			}
		}
	}
	
	.svg-cloud {
		animation-duration: 12s;
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite;
		animation-name: intro-3-clouds-animation;
		
		@for $i from 1 through 10 {
			&:nth-child(#{$i}n) {
				animation-delay: #{50*$i + ms};
			}
		}
	}
	
	@include media_1000 {
		.svg-tree,
		.svg-cloud {
			&:not(.bp1000):not(.bp400) {
				animation: none !important;
			}
		}
	}
	
	@include media_400 {
		.svg-tree,
		.svg-cloud {
			&:not(.bp400) {
				animation: none !important;
			}
		}
	}
}