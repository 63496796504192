.open-house-items {
  display: flex;
  flex-wrap: wrap;
  font-size: 15px;
  justify-content: space-between;

  .open-house-item {
    display: flex;
    max-width: 325px;
    gap: 5px;
    padding-bottom: 10px;

    .open-house-btn {
      background-color: transparent;
    }

    .open-house-btn span {
      font-weight: 700;
      font-size: 14px;
      color: #374246;
    }

    p {
      line-height: 20px;
    }



    svg {
      height: 1.5rem;
      width: 1.5rem;
    }

    svg path {
      fill: #374246;
    }

    .add-to-calendar button {
      background: transparent !important;
      border: 0 !important;
      box-shadow: none !important;
    }

    .atcb-button {
      background-color: #007bff;
      color: #ffffff;
    }

    add-to-calendar-button#css-part-example::part(atcb-button) {
      background-color: transparent;
      box-shadow: none;
      border: 0;
      margin: 0;
      padding: 0;
      min-width: 200px;
      justify-content: left;
    }

    #css-part-example .atcb-icon::part(atcb-button) {
      display: none;
    }
    add-to-calendar-button#css-part-example::part(.atcb-checkmark)
    {
      top: -0.5em;
      right: 2.5em;
    }

  }

}
