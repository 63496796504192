.radio-row {
	$r: &;
	display: flex;
	align-items: stretch;
	justify-content: flex-start;

	&._custom {
		display: block;
	}

	&._hide {
		@include absolute;
		opacity: 0;
		visibility: hidden;
		display: none;
	}
	
	&__item {
		position: relative;
		margin-right: 5px;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		min-width: 40px;
		min-height: 40px;
		
		&:last-child {
			margin-right: 0;
		}

		&._custom {
			margin: 3px 0;
			min-height: 30px;
		}
	}
	
	&__input,
	&__indicator {
		border-radius: inherit;
		@include absolute;
		width: 100%;
		height: 100%;

		&._custom {

		}
	}
	
	&__input {
		opacity: 0;
		cursor: pointer;
		z-index: 3;
		
		&:hover {
			& ~ #{$r}__indicator {
				background: $gray3;
			}
		}
		
		&:checked {
			& ~ #{$r}__indicator {
				background: $fuchsia;
			}
			
			& ~ #{$r}__text{
				color: $white;
			}
		}
	}

	&__button {
		position: relative;
		border-radius: 5px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		min-width: 40px;
		color: $gray0;
		z-index: 2;
		padding: 0 12px;
		margin: 3px 0;
		min-height: 30px;

		&:hover {
			background: $gray3;
		}

		&._active {
			background: $fuchsia;
			color: $white;
		}
	}

	&.dropdown-submenu-button {
		&._hide {
			@include absolute;
			opacity: 0;
			visibility: hidden;
		}
	}
	
	&__indicator {
		background: $gray2;
		z-index: 1;
	}
	
	&__text {
		position: relative;
		color: $gray0;
		z-index: 2;
		padding: 0 12px;
	}

	@include media_700 {
		&__item {
			min-width: 35px;
			min-height: 35px;
		}
		
		&__text {
			padding: 0 10px;
		}
	}
}