.contacts-map {
	&__wrap {
		position: relative;
		
		&::before {
			content: '';
			@include absolute;
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			border: 1px solid $gray-line;
			border-radius: 20px;
			z-index: 1;
		}
	}
	
	&__header {
		position: relative;
		z-index: 2;
	}
	
	&__table {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;
	}
	
	&__col {
		position: relative;
		padding-right: 20px;
		padding-left: 30px;
		
		a {
			word-break: break-all;
		}
		
		&:nth-child(3n - 2) {
			&::after {
				display: none;
			}
		}
		
		&::before {
			display: block;
			position: relative;
			@extend .mb20;
			font-size: 40px;
			color: $dark1;
		}
	}
	
	&__text {
		max-width: 243px;
	}
	
	&__map-container {
		position: relative;
		width: 100%;
		height: 600px;
		z-index: 2;
	}
	
	@include media_min-1300 {
		&__col {
			width: calc(100% / 10 * 3);
			
			&:nth-child(3n - 2) {
				padding-left: 0;
			}
		}
	}
	
	@include media_min-700 {
		&__col {
			&::after {
				content: '';
				@include absolute(0,auto,auto);
				width: 1px;
				height: 108px;
				background: $gray-line;
			}
		}
	}
	
	@include media_1300 {
		&__col {
			width: calc(100% / 3);
		}
		
		&__map-container {
			height: 412px;
		}
	}
	
	@include media_1000 {
		&__col {
			padding-left: 20px;
			
			&::before {
				font-size: 30px;
			}
		}
		
		&__map-container {
			height: 350px;
		}
	}
	
	@include media_700 {
		&__table {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			padding-left: 30px;
			padding-right: 30px;
		}
		
		&__col {
			width: 100%;
			padding: 0 0 20px 0;
			border-bottom: 1px solid $gray-line;
			
			&:last-child {
				border-bottom: none;
				padding-bottom: 0;
			}
			
			&::before {
				font-size: 20px;
			}
		}
		
		&__map-container {
			height: 300px;
		}
	}
}