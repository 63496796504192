@keyframes estate-card-show {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.viewings-list {
	.estate-card {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.estate-cards-list {
	position: relative;
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	flex-wrap: wrap;
	width: calc(100% + 20px);
	left: -10px;

	.estate-card {
	    width: calc(100% / 3 - 20px);
	    margin: 0 10px 20px;
		opacity: 0;
		animation: estate-card-show .7s ease both;

		@for $i from 1 through 12 {
			&:nth-child(#{$i}) {
				animation-delay: #{($i - 1) * 100 + ms};
			}
		}

		&._disable-animation {
			animation: none;
			opacity: 1;
		}
	}

	@include media_min-2400 {
		.estate-card {
			width: calc(100% / 4 - 20px);
		}
	}

	@include media_1500 {
		.estate-card {
			width: calc(50% - 20px);
		}
	}

	@include media_1300 {
		.estate-card {
			width: calc(100% - 20px);
		}
	}

	@include media_1000 {
		.estate-card {
			width: calc(50% - 20px);
		}
	}

	@include media_500 {
		.estate-card {
			width: calc(100% - 20px);
		}
	}
}

.estate-cards-slider {
	position: relative;
	width: 100%;
	border-radius: 20px;
	overflow: hidden;
	z-index: 3;
	background: $gray2;
	@include slider-preload-visible(&);
	@extend ._absolute-dots-nav;

	&__item {
		position: relative;
		width: 100%;
		height: 260px;
		z-index: 2;
	}

	&__img {
		display: block;
		z-index: 1;
	}

	@include media_700 {
		&__item {
			height: 180px;
		}
	}

	&._g3-bg {
		background: $gray3;

		.default-img-bg {
			background-color: $gray3;
		}
	}
}

.estate-card {
	$r: &;
	display: block;
	position: relative;
	border-radius: 20px;
	overflow: hidden;
	background: $white;
	break-inside: avoid;

	&__arrow,
	&__add-to-favotite {
		opacity: .5;
	}

	&:hover {
		#{$r}__arrow,
		#{$r}__add-to-favotite {
			opacity: 1;
		}

		#{$r}__location {
			color: $turquoise;
		}
	}

	&::before {
		content: '';
		@include absolute;
		width: 100%;
		height: 100%;
		border: 1px solid $gray-line;
		box-sizing: border-box;
		border-radius: inherit;
		z-index: 1;
	}

	&__slider-wrap {
		position: relative;
		z-index: 2;
	}

	&__header {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		pointer-events: none;
		z-index: 5;
		@include absolute(0,auto,auto);
		padding: 10px 10px 0;
		width: 100%;
	}

	&__labels-wrap {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;
		width: calc(100% - 33px);
		padding-right: 15px;
	}

	&__label {
		position: relative;
		margin-bottom: 10px;
		margin-right: 8px;
		padding: 0 8px;
		color: $dark1;

		&::before {
			content: '';
			@include absolute(0,auto,auto);
			z-index: -1;
			width: 100%;
			height: calc(100% - 1px);
			border-radius: 10px;
			background: $yellow;
		}
	}
	&__label_success {
		color: $white;

		&::before {
			background: $success;
		}
	}
	&__label_danger {
		color: $white;

		&::before {
			background: $pinterest;
		}
	}
	&__label_warning {
		color: $white;
		padding: 11px !important;
		&::before {
			background: $red;
		}
	}
	&__label_fire {
		font-size: 15px;
		position: absolute;
		z-index: 1;
		top: 2px;
		left: 4px;
		line-height: 22px;
	}

	&__arrows-wrap {
		width: 100%;
		height: 31px;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: 0 10px;
		pointer-events: none;
		@include absolute;
		z-index: 6;
	}

	&__price {
		padding: 0 15px;
	}

	&__description {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		padding-left: 40px;
		padding-right: 40px;
		z-index: 2;
	}

	&__title,
	&__location {
		color: $dark1;
		display: inline-block;
	}

	&__title {
		word-break: break-word;
	}

	&__location {
		transition: $default-transition;
	}

	&__title-wrap {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}

	&__date {
		flex: 0 0 auto;
		margin-left: 20px;
		background: $pink-pdra;
		color: $dark1;
		border-radius: 100px;
		padding: 6px 20px 4px;
	}

	&__controls-wrap {
		position: relative;
		width: 100%;
		margin-top: auto;

		.btn-style {
			margin-right: 10px;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	&__controls-list {
		position: relative;
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin-top: -10px;

		.btn-style,
		.controls-bar {
			margin-right: 10px;
			margin-top: 10px;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	&._transparent-controls {
		&:hover {
			#{$r}__arrow {
				opacity: .5;
			}
		}

		#{$r}__arrow {
			opacity: 0;

			&:hover {
				opacity: 1;
			}
		}

		#{$r}__add-to-favorite {
			opacity: 1;

			&::after {
				opacity: .5;
			}

			&:hover {
				&::after {
					opacity: 1;
				}
			}
		}
	}

	&._small {
		#{$r}__description {
			padding-left: 20px;
			padding-right: 20px;
		}

		#{$r}__labels-wrap {
			padding-right: 10px;
		}

		#{$r}__label {
			margin-bottom: 5px;
			margin-right: 5px;
			padding: 0 10px;
			line-height: 1.8;

			&::before {
				top: -1px;
				height: 100%;
			}
		}

		.estate-cards-slider {
			&__item {
				height: 160px;
			}
		}
	}

	&._horizontal {
		width: 100%;

		&:hover {
			#{$r}__location {
				color: $dark1;
			}
		}
	}

	@include media_min-950 {
		&._horizontal {
			display: flex;

			.slick-list,
			.slick-track,
			.estate-cards-slider,
			.estate-cards-slider__item {
				height: 100%;
			}

			#{$r}__slider-wrap {
				width: 360px;
				min-height: 260px;
			}

			#{$r}__description {
				width: calc(100% - 360px);
				padding-left: 30px;
				padding-right: 30px;
			}
		}
	}

	@include media_1000 {
		&__arrows-wrap {
			display: none;
		}
	}

	@include media_950 {
		&__date {
			padding: 4px 14px 3px;
		}
	}

	@include media_700 {
		&__description {
			padding-left: 30px;
			padding-right: 30px;
		}

		&__date {
			padding: 2px 10px 1px;
		}
	}

	@include media_500 {
		&__title-wrap {
			flex-direction: column-reverse;
			align-items: flex-start;
			justify-content: flex-start;
		}

		&__date {
			margin-bottom: 10px;
			margin-left: 0;
		}
	}
}

.gray-mls-after {
	color: $gray1;

	&.icon-mls-min {
		display: inline-flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: row-reverse;

		&::before {
			font-size: 18px;
			margin-left: 5px;
		}
	}
}
