@mixin horizontal-slider-nav-styles {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	
	&__arrows-wrap {
		flex-direction: row;
	}
	
	&__arrow {
		width: 50px;
		height: 40px;
		
		&_left {
			border-radius: 10px 0 0 10px;
		}
		
		&_right {
			border-radius: 0 10px 10px 0;
			border-top-width: 1px;
			border-left-width: 0;
		}
	}
}

@mixin horizontal-slider-counter-styles {
	&__counter {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 0;
		margin-right: 25px;
	}
	
	&__current,
	&__total {
		width: auto;
		height: auto;
		display: block;
		margin: 0;
	}
	
	&__total {
		&::before {
			content: '/';
			display: inline;
			margin: 0 .3em;
		}
	}
	
	&__separate {
		display: none;
	}
}

.slider-nav {
	position: relative;
	pointer-events: auto;
	
	&__counter {
		text-align: center;
		margin-bottom: 10px;
	}
	
	&__current,
	&__total {
		width: 40px;
		height: 40px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
	}
	
	&__current {
		color: $dark1;
	}
	
	&__total {
		color: $gray1;
	}
	
	&__separate {
		display: block;
		width: 20px;
		height: 1px;
		background: $gray-line;
		margin: 0 auto;
	}
	
	&__arrows-wrap {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}
	
	&__arrow {
		color: $dark1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		border: 1px solid $gray-line;
		box-sizing: content-box;
		width: 40px;
		height: 50px;
		background: $white;
		transition: $default-transition;
		
		&:hover {
			background: $gray4;
		}
		
		&::before {
			font-size: 12px;
			transition: $default-transition;
		}
		
		&:not(.slick-arrow) {
			opacity: 0;
		}
		
		&.slick-disabled {
			cursor: default;
			
			&::before {
				opacity: 0.3;
			}
		}
		
		&_left {
			border-radius: 10px 10px 0 0;
		}
		
		&_right {
			border-radius: 0 0 10px 10px;
			border-top-width: 0;
		}
	}
	
	&._horizontal & {
		@include horizontal-slider-nav-styles;
	}
	
	@include media_1000 {
		@include horizontal-slider-nav-styles;
		@include horizontal-slider-counter-styles;
	}
}
