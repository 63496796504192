.agent {
	$r: &;
	background: $dark1;
	border-radius: 20px;
	padding: 50px 35px;
	display: flex;
	
	&__container {
		position: relative;
		display: block;
		width: 100%;
		max-height: 100%;
		padding-left: 15px;
		padding-right: 15px;
		
		&._scroll-initialized {
			padding-right: 0;
		}
	}
	
	&__person {
		margin-bottom: 24px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
	}
	
	&__photo-wrap {
		position: relative;
		width: 60px;
		height: 60px;
		border-radius: 20px;
		overflow: hidden;
		z-index: 2;
	}
	
	&__photo {
		z-index: 1;
	}
	
	&__info {
		width: calc(100% - 60px);
		padding-left: 20px;
	}
	
	&__name {
		color: $white;
		margin-bottom: 2px;
	}
	
	&__agency {
		color: $gray1;
	}
	
	&__phone {
		display: inline-block;
		margin-right: 10px;
		
		&:last-child {
			margin-right: 0;
		}
	}
	
	&__phone-wrap {
		display: flex;
		align-items: baseline;
		justify-content: flex-start;
		flex-wrap: wrap;
	}
	
	&__phone-label {
		color: $white;
	}
	
	&._simple {
		padding: 25px;
		
		#{$r}__container {
			max-width: 210px;
			margin: auto;
			padding: 0;
		}
		
		#{$r}__person {
			margin-bottom: 10px;
		}
	}
	
	&._mh {
		max-height: 100%;
	}
	
	&._separate & {
		&__person {
			padding-bottom: 40px;
			margin-bottom: 40px;
			border-bottom: 1px solid $gray0;
		}
	}
	
	&._big-photo & {
		&__photo-wrap {
			width: 100px;
			height: 100px;
		}
		
		&__info {
			width: calc(100% - 100px);
			padding-left: 40px;
		}
	}
	
	@include media_700 {
		padding: 30px 15px;
		
		&__person {
			flex-direction: column;
		}
		
		&__info {
			width: 100%;
			padding-left: 0;
			margin-top: 15px;
		}
		
		&._big-photo & {
			&__photo-wrap {
				width: 70px;
				height: 70px;
			}
			
			&__info {
				width: 100%;
				padding-left: 0;
			}
		}
		
		&._separate & {
			&__person {
				padding-bottom: 20px;
				margin-bottom: 20px;
			}
		}
	}
}


.popup-agent-wrap {
	position: relative;
	padding: 45px 60px;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	
	.agent {
		margin: auto 0;
		width: 100%;
		max-width: 830px;
		max-height: 100%;
	}
	
	@include media_1000 {
		padding: 45px 40px;
	}
	
	@include media_700 {
		padding: 45px 0 0;
	}
}