.simple-search {
	position: relative;
	width: 100%;
	height: 44px;
	background: $white;
	border-radius: 5px;
	
	.autofill {
		height: 100%;
		border-radius: inherit;
	}
	
	.input-style {
		height: 100%;
		margin-bottom: 0;
		z-index: 1;
		border-radius: inherit;
		
		&__container {
			height: 100%;
			border-radius: inherit;
		}
		
		&__input {
			height: 100%;
			padding-right: 50px;
			border-radius: inherit;
		}
	}
	
	.icon-btn {
		@include absolute(0,7px,0,auto);
		z-index: 2;
	}
	
	&._big {
		height: 60px;
		border-radius: 20px;
		
		.input-style {
			&__input {
				padding-right: 70px;
			}
		}
	}
	
	&._bordered {
		box-shadow: 0 0 0 5px $dark1;
	}
	
	&._max-w {
		max-width: 600px;
	}
	
	@include media_700 {
		.icon-btn {
			right: 5px;
		}
		
		&._big {
			height: 46px;
			border-radius: 10px;
			
			.input-style {
				&__input {
					padding-right: 50px;
				}
			}
		}
		
		&._bordered {
			box-shadow: 0 0 0 3px $dark1;
		}
	}
}