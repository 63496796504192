.book-a-tour {
	position: relative;
	
	.circles-bg {
		top: 5px;
	}

	&__wrap {
		width: 100%;
		background: $dark1;
		border-radius: 20px;
		color: $white;
	}
	
	&__title {
		color: $white;
	}
	
	&__table {
		display: flex;
		align-items: stretch;
		justify-content: space-between;
	}
	
	&__col {
		position: relative;
		
		&_left {
			width: calc(100% / 10 * 3 - 10px);
			
			&::after {
				content: '';
				@include absolute(0,auto,auto,calc(100% / 3 * 3.5));
				width: 1px;
				height: 100%;
				max-height: 264px;
				background: $gray0;
			}
		}
		
		&_right {
			width: calc(100% / 10 * 6 - 10px);
			display: flex;
			align-items: stretch;
		}
	}
	
	&__img {
		width: 100%;
		max-width: 125px;
		position: relative;
		@include rh(100/125);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
		
		&._in-window {
			background-image: url(../../images/bg/key.svg);
		}
	}
	
	
	@include media_1300 {
		&__wrap {
			padding-left: 100px;
			padding-right: 100px;
		}
		
		&__table {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
		}
		
		&__col {
			width: 100%;
			
			&_left {
				padding-left: 165px;
				padding-bottom: 40px;
				margin-bottom: 40px;
				border-bottom: 1px solid $gray0;
				
				&::after {
					display: none;
				}
			}
		}
		
		&__img {
			@include absolute(0,auto,auto);
		}
	}
	
	@include media_1000 {
		&__wrap {
			padding-left: 60px;
			padding-right: 60px;
		}
		
		&__col {
			&_left {
				padding-bottom: 30px;
				margin-bottom: 30px;
			}
		}
	}
	
	@include media_700 {
		&__wrap {
			padding-left: 30px;
			padding-right: 30px;
		}
		
		&__col {
			&_left {
				padding-left: 0;
				padding-bottom: 0;
				margin-bottom: 35px;
				border-bottom: none;
			}
		}
		
		&__img {
			display: none;
		}
	}
}