.select-dropdown-animations {
    opacity: 0;
    transition: opacity .3s ease;
}

.select-dropdown {
    z-index: 10;
    position: absolute;
    left: 0;
    top: calc(100% + 10px);
    width: 100%;
    overflow: hidden;
    background: $white;
    pointer-events: none;
    padding: 20px 10px 20px 20px;
    border: 1px solid $gray-line;
    border-radius: 10px;
    @extend .select-dropdown-animations;
}

.select-dropdown-active {
    opacity: 1;
    pointer-events: all;
}

.select-scroll {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    max-height: 165px;
}

.select-option {
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    color: $gray0;
    transition: $default-transition;
    @extend .small-text;
    margin-top: 10px;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &:hover {
        color: $dark1;
    }

    &._active {
        font-weight: 700;
        color: $dark1;
    }
}

@mixin select-module-styles {
    $r: &;
    @include input-wrap;
    perspective: 1000px;
    z-index: 10;
    color: $dark1;

    &__text-block {
        max-width: 100%;
        overflow: hidden;
        font-weight: 700;
    }

    &__input-container:not(._inline) & {
        &__text-block {
            line-height: 1;
        }
    }

    &__input-container._inline ~ &__dropdown {
        min-width: 150px;
        right: 0;
        left: auto;


        &._custom {
            min-width: 200px;
        }
    }

    &__label {
        color: $white;
    }

    &__input-container {
        @include input-style;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        padding-right: 30px;
        @extend .angle-down-after;

        &::after {
            right: 15px;
        }

        &.icon-arrow-up:before {
            margin-right: 5px;
        }

        &.icon-arrow-down:before {
            margin-right: 5px;
        }
    }

    &__dropdown {
        @extend .select-dropdown;

        &._rel-h {
            display: flex;
            flex-direction: column;

            #{$r}__scroll {
                max-height: 100%;
            }
        }
    }

    &__scroll {
        @extend .select-scroll;
    }

    &__option {
        @extend .select-option;
    }

    &._thin-text & {
        &__text-block {
            font-weight: 500;
        }
    }

    &._active {
        z-index: 20;
    }

    &._active & {
        &__input-container {
            &::after {
                transform: scaleY(-1);
            }
        }

        &__dropdown {
            @extend .select-dropdown-active;
        }
    }

    &__select.error ~ &__input-container {
        background: transparent;
        color: $gray2;
        border-color: $pink-red;
    }

    &__select.error ~ &__input-container._transparent {
        background: transparent;
        color: $pink-pdra;
        font-weight: 700;
    }

    &__select.error ~ &__input-container._fill-white,
    &__select.error ~ &__input-container._bordered,
    &__select.error ~ &__input-container._bordered-med {
        background: $pink-pdra;
        border-color: $pink-pdra;
        color: $dark1;
        font-weight: 700;
    }

    &__select.error ~ &__input-container._bordered-bright {
        background: $pink-pdra;
        border-color: $pink-pdra;
        color: $dark1;
        font-weight: normal;
    }
}

.select-module {
    @include select-module-styles;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;

    p {
        display: block;

        span {
            display: block;
        }
    }
}
