@mixin collapseStyles {
  &__col {
    width: 100%;
    padding-right: 0;
    margin-top: 0;
  }

  &__list-wrap {
    display: none;
  }

  &__list {
    padding-bottom: 15px;
  }

  &__title {
    padding: 15px 15px 15px 0;
    border-top: 1px solid $gray-line;

    &::after {
      content: '\e900';
      font-family: $icomoon;
      font-size: 6px;
      color: $dark1;
      line-height: 1;
      font-weight: 100;
      display: block;
      @include absolute(calc(50% - 3px), 0, auto, auto);
      transition: $default-transition;
      transform-origin: 50% 50%;
    }

    &._active {
      &::after {
        transform: scaleY(-1);
      }
    }
  }
}

.collapse-nav-list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  &__col {
    width: 100%;
    padding-right: 20px;

    &._full-width {
      padding-right: 0;
      width: 100%;
    }

    &._half-width {
      padding-right: 0;
      width: 50%;
    }
  }

  &__title {
    position: relative;
    display: block;
    color: $dark1;
  }

  &__list-wrap {
    width: 100%;

    &._flex {
      display: flex;
    }
  }

  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &._3-col & {
    &__col {
      width: calc(100% / 3);

      &._full-width {
        width: 100%;
      }
    }
  }

  @include media_1500 {
    &._3-col & {
      &__col {
        width: 50%;
      }
    }
  }

  @include media_1300 {
    &__col {
      width: 50%;
    }

    &._3-col & {
      @include collapseStyles;
    }
  }

  @include media_700 {
    margin-top: 0;

    &:not(._3-col) & {
      @include collapseStyles;
    }
  }
}

.collapse-nav-doted {
  width: 100% !important;

  &__list {
    flex-direction: row !important;
    flex-wrap: wrap;
    list-style: disc inside;
    display: flex;

    li::marker {
      margin: 0;
      padding: 0;
    }

    a {
      list-style-type: circle;
      position: relative;
      left: -10px;
    }

    a:after {
      content: " ";
    }

    a:last-child:after {
      display: none;
    }

  }
}
