$timingFunction: ease;
$baseDuration: 0.3;

$default-transition: all #{($baseDuration * 1) + s} $timingFunction;
$default-transition-2: all #{($baseDuration * 2) + s} $timingFunction;
$default-transition-3: all #{($baseDuration * 3) + s} $timingFunction;
$default-transition-4: all #{($baseDuration * 4) + s} $timingFunction;
$default-transition-5: all #{($baseDuration * 5) + s} $timingFunction;

$default-color-transition: color #{($baseDuration * 1) + s} $timingFunction;
$default-background-transition: background #{($baseDuration * 1) + s} $timingFunction;
$default-background-color-transition: background-color #{($baseDuration * 1) + s} $timingFunction;
$default-transform-transition: transform #{($baseDuration * 1) + s} $timingFunction;

.animate-in-window {
	animation-play-state: paused;
}

._in-window {
	.animate-in-window {
		animation-play-state: running;
	}
}

@keyframes leaves-animation {
	0%, 100% {
		transform: skewX(0deg);
	}
	
	50% {
		transform: skewX(3deg);
	}
}

@keyframes leaves-animation-2 {
	0%, 100% {
		transform: skewX(0deg);
	}
	
	25% {
		transform: skewX(2deg);
	}
	
	75% {
		transform: skewX(-2deg);
	}
}


@keyframes leaves-animation-3 {
	0%, 100% {
		transform: skewX(0deg);
	}
	
	50% {
		transform: skewX(-3deg);
	}
}
