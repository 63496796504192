.custom-table {
  width: 100%;
  border-collapse: collapse;

  tr td, tr th {
    padding-left: 0 !important;
  }


  thead {
    th {
      font-weight: bold;
      color: #c2c2c2;
      padding: 10px;
    }
  }


  tbody {
    td {
      padding: 10px;
    }
  }

  table {
    border: 1px solid #dee2e6;
    border-radius: 4px;
    overflow: hidden;
  }

}

.FeatureList {
  width: 100%;
  display: inline-block;
  margin-top:70px;
}

.custom-table-key-val {

  columns: 2;

  .table-block {
    display: inline-block;
  }

  .table {
    table-layout: fixed;
    width: 100%;
  }

  .table-head {
    width: 50%;
    border-bottom: unset;
    padding-bottom: 20px;
    line-height: 25px;
    color: #ACB8C0;
  }

  .table-val {
    width: 50%;
    border-bottom: unset;
    padding: 5px 5px;
    color: #374246;
  }
}
.property-table__list.FeatureList
{
  opacity: 1 !important;
  width: 100% !important;
  //flex-wrap: wrap;
  //display: flex;
  columns: 2;
}

.FeatureList .property-table__col
{
  display: block !important;
  margin-bottom: 45px;
  max-width: 100% !important;
  width: 100% !important;
  overflow: hidden;
}

