$margins: (
	default: (
		5: 5px,
		10: 10px,
		15: 15px,
		20: 20px,
		30: 30px,
		40: 40px,
		50: 50px,
		60: 60px,
		70: 70px,
		80: 80px,
		100: 100px,
		150: 150px,
		70_2: 70px,
		40_2: 40px,
	),
	$b1300: (
		150: 130px,
	),
	$b700: (
		15: 10px,
		20: 15px,
		30: 20px,
		40: 30px,
		50: 30px,
		60: 40px,
		70: 50px,
		80: 60px,
		100: 80px,
		150: 100px,
		70_2: 20px,
		40_2: 15px,
	)
);

@mixin margin-classes($key, $val) {
	.mt#{$key} {margin-top: $val}
	.mtr#{$key} {margin-top: -$val}
	.mb#{$key} {margin-bottom: $val}
	.pt#{$key} {padding-top: $val}
	.pb#{$key} {padding-bottom: $val}
}

@each $res, $arr in $margins {
	@if $res == default {
		@each $key, $val in $arr {
			@include margin-classes($key, $val);
		}
	} @else {
		@media #{$res} {
			@each $key, $val in $arr {
				@include margin-classes($key, $val);
			}
		}
	}
}
