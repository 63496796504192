.disclaimers {
  &__container {
    margin-top: 50px;
    line-height: 1.4;

    h3, h4, h5, h6 {
      margin-top: 10px;
      margin-bottom: 15px;
      font-weight: 700;
    }


    h1 {
      font-size: 36px;
      margin-bottom: 20px;
      font-weight: bold;
    }

    h2 {
      font-size: 30px;
    }

    h3 {
      font-size: 24px;
    }

    h4 {
      font-size: 18px;
    }

    h5 {
      font-size: 14px;
    }

    h6 {
      font-size: 12px;
    }

    p {
      margin: 0 0 20px;
    }
  }
}
.disclaimer-div
{
  .note._mls .note__text-wrap::before
  {
      font-size: 70px;
      position: absolute;
      top: -21px;
      right: -29px;
  }
  .note._mls .note__text-wrap {
    padding-right: 41px;
  }
}

