.map-menu {
	background: $gray2;
	border-radius: 20px;
	padding-left: 50px;
	padding-right: 50px;
	height: 100%;
	width: 100%;
	
	[data-scrollbar] {
		.scroll-content {
			padding-right: 30px;
		}
		
		.scrollbar-track-y {
			.scrollbar-thumb {
				background: $gray1;
			}
		}
	}
	
	&__text {
		color: $gray1;
	}
	
	&__container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		height: 100%;
		width: 100%;
	}
	
	&__content-wrap {
		position: relative;
		width: 100%;
		flex: 1 1 0;
	}
	
	&__scroll-container {
		@include absolute(0,auto,auto);
		height: 100%;
		width: calc(100% + 30px);
	}
	
	&__scroll-wrap {
		position: relative;
		height: 100%;
		width: 100%;
	}
	
	&__yelp-label {
		position: relative;
		display: inline-flex;
		align-items: flex-start;
		justify-content: flex-start;
		color: $gray1;
		
		.yelp-logo {
			flex: 0 0 auto;
			margin-left: 5px;
			margin-top: -2px;
			width: 40px;
			height: 20px;
		}
	}
	
	&__expand-button {
		@include absolute(0, auto, auto, 0);
		width: 100%;
		height: 50px;
		border-radius: 20px 20px 0 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		z-index: 2;
		color: $gray5;
		
		&::before {
			font-size: 7px;
			transition: $default-transition;
			transform-origin: 50% 50%;
		}
		
		&._active {
			&::before {
				transform: scaleY(-1);
			}
		}
	}
	
	@include media_700 {
		padding-left: 40px;
		padding-right: 40px;
		
		[data-scrollbar] {
			.scroll-content {
				padding-right: 13px;
			}
		}
		
		&__expand-button {
			height: 30px;
		}
		
		&__scroll-container {
			width: calc(100% + 13px);
		}
	}
}