.select-search {
	position: relative;
	width: 100%;
	max-width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	height: 44px;
	
	.autofill,
	.input-style,
	.select-module {
		height: 100%;
		margin-bottom: 0;
		z-index: 1;
	}
	
	.autofill {
		flex: 1 1 0;
	}
	
	.input-style {
		&__container {
			height: 100%;
		}
		
		&__input {
			height: 100%;
			padding-right: 50px;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
	
	.select-module {
		width: 177px;
		
		&__input-container {
			height: 100%;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
	
	.icon-btn {
		@include absolute(0,6px,0,auto);
		z-index: 2;
	}
	
	
	@include media_1300 {
	
	}
	
	@include media_1000 {
		.select-module {
			width: 156px;
		}
	}
	
	@include media_700 {
		height: 100%;
		flex-direction: column;
		
		.icon-btn {
			top: auto;
			bottom: 7px;
		}
		
		.autofill,
		.select-module {
			width: 100%;
			height: 50%;
			
			&__dropdown {
				top: 100%;
			}
		}
		
		.select-module {
			z-index: 3;
			
			&__input-container {
				border-radius: 10px 10px 0 0;

				&.icon-arrow-up:before, .icon-arrow-down:before {
					width: 15px;
				}
			}
		}
		
		.input-style {
			&__input {
				padding-right: 70px;
				border-radius: 0 0 10px 10px;
				border-top: none;
			}
		}
		
		&__clear-button {
			@include absolute(auto,40px,7px,auto);
			padding: 10px;
			z-index: 2;
			
			&::before {
				font-size: 10px;
				color: $dark1;
				line-height: 1;
				font-weight: 100;
				display: block;
			}
		}
	}
}