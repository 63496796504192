.separator {
	position: relative;
	width: 100%;
	
	&__container {
		position: relative;
		min-height: 1px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
	
	&__line {
		@include absolute;
		display: block;
		width: 100%;
		height: 1px;
		background: $gray-line;
		z-index: 1;
	}
	
	&__text {
		position: relative;
		padding: 8px 7px;
		margin-bottom: -0.1em;
		background: $white;
		color: $gray0;
		z-index: 2;
	}
}