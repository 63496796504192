body {
  position: relative;
  width: 100%;
  font-family: $montserrat;
  font-style: normal;
  font-weight: 500;
  color: $gray0;
}

span {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & > .check {
    @extend .mb10;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.select-lang-row {
  a {
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.bp-detect {
  @include absolute(0, auto, auto, -100vw);
  width: 1px;
  height: 1px;
  pointer-events: none;
  opacity: 0;
}

._ta-center {
  text-align: center;
}

._ta-left {
  text-align: left;
}

._ta-right {
  text-align: right;
}

.pseudo-hidden {
  position: absolute;
  left: -200vw;
  opacity: 0;
  z-index: -999;
  pointer-events: none;
  overflow: hidden;
  transform: scale(0);
}

.wrapper {
  display: block;
  width: 100%;
  overflow: hidden;
}

.container {
  position: relative;
  width: 100%;
  max-width: 1280px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  z-index: 5;

  @include media_1300 {
    max-width: none;
    padding-left: 100px;
    padding-right: 100px;
  }

  @include media_1000 {
    padding-left: 80px;
    padding-right: 80px;
  }

  @include media_700 {
    padding-left: 40px;
    padding-right: 40px;
  }

  @include media_500 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.container-wide {
  position: relative;
  width: 100%;
  max-width: 1920px;
  padding-left: 100px;
  padding-right: 100px;
  margin-left: auto;
  margin-right: auto;
  z-index: 5;

  @include media_1500 {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.inner-container {
  position: relative;
  width: calc(100% / 12 * 10);
  margin: 0 auto;
  z-index: 5;

  @include media_1300 {
    &:not(._fw1000):not(._fw700) {
      width: 100%;
    }
  }

  @include media_1000 {
    &._fw1000 {
      width: 100%;
    }
  }

  @include media_700 {
    &._fw700 {
      width: 100%;
    }
  }
}

.menu-wrap {
  @include absolute(calc(100% - 20px), 100vw, auto, auto);
  height: auto;
  transform: translateY(20px);
  opacity: 0;
  transition-property: right, opacity, transform;
  transition-duration: 0s, .3s, .3s;
  transition-delay: .3s, 0s, 0s;
  background: $white;
  border: 1px solid $gray-line;
  box-sizing: border-box;
  border-radius: 10px;
}

.menu-wrap-active {
  right: 0;
  transform: translateY(0);
  opacity: 1;
  transition-delay: 0s, 0s, 0s;
}

.default-img-bg {
  background-image: url(../../images/default-img.svg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: $gray2;
}

.default-img-bg-small {
  background-image: url(../../images/default-img-small.svg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: $gray2;
}

.lc-cm {
  &:last-child {
    margin-bottom: 0;
  }
}

.border-bottom-none {
  border-bottom: none !important;
}

.justify-content-space-between {
  justify-content: space-between !important;
}