.table-nav {
	&__table {
		position: relative;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;
		width: calc(100% + 20px);
		left: -10px;
	}
	
	&__col {
		width: calc(100% / 12 * 3);
		padding: 0 10px;
	}
	
	&__content {
		max-width: 250px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}
	
	@include media_1300 {
		&__col {
			width: calc(100% / 12 * 4);
		}
	}
	
	@include media_1000 {
		&__col {
			width: 50%;
		}
	}
	
	@include media_700 {
		&__col {
			width: 100%;
		}
	}
}