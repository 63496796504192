.local-info {
	&__map-wrap {
		position: relative;
		width: 100%;
		height: 500px;
	}
	
	&__yelp-cards-wrap {
		display: flex;
		align-items: stretch;
		justify-content: space-between;
	}
	
	&__slider-wrap {
		width: calc(100% - 70px);
		overflow: hidden;
	}
	
	&__nav-wrap {
		width: 70px;
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
	}
	
	@include media_1000 {
		&__slider-wrap {
			width: 100%;
		}
		
		&__nav-wrap {
			display: none;
		}
	}
	
	@include media_700 {
		&__map-wrap {
			height: 320px;
		}
	}
}