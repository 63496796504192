.estate-gallery {
	position: relative;
	width: 100%;
	
	&__nav-wrap {
		@include absolute(0,0,0,auto);
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: center;
		z-index: 2;
		pointer-events: none;
		
		.slider-nav {
			margin-right: -50%;
		}
	}
	
	&__slider-wrap {
		position: relative;
		width: 100%;
		overflow: hidden;
		z-index: 1;
	}
}

.estate-gallery-slider {
	$r: &;
	position: relative;
	width: calc(100% + 20px);
	left: -10px;
	@include slider-preload(&);
	
	&:not(.slick-initialized) {
		height: 400px;
	}
	
	&__item {
		width: 100%;
		padding: 0 10px;
	}
	
	&__container {
		position: relative;
		height: 400px;
		width: calc(100% + 10px);
		left: -5px;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: space-between;
		align-content: flex-start;
	}
	
	&__img-wrap {
		position: relative;
		overflow: hidden;
		z-index: 2;
		background: $gray2;
		margin: 0 5px;
		height: calc(50% - 5px);
		width: calc(100% / 12 * 3 - 10px);
		cursor: pointer;
		
		&:nth-child(1) {
			height: 100%;
			width: calc(100% / 12 * 6 - 10px);
			border-radius: 20px 0 0 20px;
		}
		
		&:nth-child(4) {
			border-top-right-radius: 20px;
		}
		
		&:nth-child(5) {
			border-bottom-right-radius: 20px;
		}
	}
	
	&__img-wrap {
		&:hover {
			#{$r}__img {
				transform: scale(1.05);
			}
		}
		
		&:nth-child(1) {
			&:hover {
				#{$r}__img {
					transform: scale(1.025);
				}
			}
		}
	}
	
	&__img {
		z-index: 1;
		will-change: transform;
		backface-visibility: hidden;
		transform-origin: 50% 50%;
		transition: $default-transition;
	}
	
	@include media_1300 {
		&:not(.slick-initialized) {
			height: 368px;
		}
		
		&__container {
			height: 368px;
		}
		
		&__img-wrap {
			width: calc(100% / 12 * 4 - 10px);
			
			&:nth-child(1) {
				width: calc(100% / 12 * 8 - 10px);
			}
			
			&:nth-child(2) {
				border-top-right-radius: 20px;
			}
			
			&:nth-child(3) {
				border-bottom-right-radius: 20px;
			}
		}
	}
	
	@include media_1000 {
		&:not(.slick-initialized) {
			height: 400px;
		}
		
		&__container {
			height: 400px;
		}
		
		&__img-wrap {
			&:nth-child(1) {
				width: calc(100% - 10px);
				border-radius: 20px;
			}
		}
	}
	
	@include media_700 {
		&:not(.slick-initialized) {
			height: 300px;
		}
		
		&__container {
			height: 300px;
		}
	}
	
	@include media_500 {
		&:not(.slick-initialized) {
			height: 180px;
		}
		
		&__container {
			height: 180px;
		}
	}
}

.pbThumbs li.active a img {
	border-color: $white;
}

#pbCloseBtn:hover:before {
	background: rgba(255, 255, 255, 0.4);
}