.svg-map {
	&__wrap {
		position: relative;
		width: 100%;
		@include rh(450/1200);
		z-index: 3;
		border-radius: 20px;
		overflow: hidden;
		border: 1px solid $gray-line;
		
		&:last-child {
			margin-bottom: 0;
		}
		
		svg {
			@include absolute(0,auto);
			width: 108%;
			height: auto;
			z-index: 1;
		}
	}
	
	@include media_700 {
		&__wrap {
			display: none;
		}
	}
}

.svg-map-city {
	$transitionTF: ease-in-out;
	$transitionD: .3s;
	cursor: pointer;
	
	.svg-map-city-border,
	.svg-map-city-arrow,
	.svg-map-city-label {
		pointer-events: none;
	}
	
	.svg-map-city-fill,
	.svg-map-city-border,
	.svg-map-city-fill-border,
	.svg-map-city-arrow,
	.svg-map-city-label {
		transition: $transitionD $transitionTF;
	}
	
	&:hover {
		.svg-map-city-fill,
		.svg-map-city-border,
		.svg-map-city-fill-border,
		.svg-map-city-arrow,
		.svg-map-city-label {
			transform: translateY(-1%);
		}
	}
	
	&:hover,
	&._active {
		.svg-map-city-fill,
		.svg-map-city-fill-border {
			fill: $gray0;
		}
		
		.svg-map-city-label._inside {
			fill: $white;
		}
	}
}

.svg-map-links {
	$r: &;
	
	&__list {
		position: relative;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;
		width: calc(100% + 10px);
		left: -5px;
	}
	
	&__item {
		margin-left: 5px;
		margin-right: 5px;
		padding: 10px 20px;
		border: 1px solid $gray5;
		border-radius: 5px;
		background: $white;
		transition: $default-transition;
		display: flex;
		align-items: baseline;
		justify-content: flex-start;
		flex-wrap: wrap;
		cursor: pointer;
		
		&:last-child {
			margin-right: 0;
		}
		
		&:hover,
		&._active {
			background: $gray2;
			
			#{$r}__text {
				color: $dark1;
			}
		}
	}
	
	&__text {
		color: $turquoise;
		transition: $default-transition;
		margin-right: 10px;
		
		&:last-child {
			margin-right: 0;
		}
	}
	
	&__label {
		color: $gray0;
	}
	
	@include media_700 {
		&__item {
			padding: 8px 15px;
		}
	}
}