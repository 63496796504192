.quotes-slider {
	&__table {
		position: relative;
		display: flex;
		align-items: stretch;
		justify-content: space-between;
		
		&::before {
			content: '';
			@include absolute;
			width: 100%;
			height: 100%;
			display: block;
			border-radius: 20px;
			background: $gray2;
			z-index: 1;
		}
	}
	
	&__col {
		position: relative;
		z-index: 2;
		
		&_left {
			width: calc(100% / 12 * 9 - 10px);
		}
		
		&_right {
			width: calc(100% / 12 * 3 - 10px);
			background: $white;
			border: 1px solid $gray-line;
			border-radius: 20px;
		}
	}
	
	&__text-wrap {
		position: relative;
		width: calc((100% + 10px) / 9 * 6);
		margin-left: calc((100% + 10px) / 9 * 2);
		
		&::before {
			@include absolute-cm(0,calc(100% + 20px),auto,auto);
			font-size: 72px;
			color: $gray5;
			display: block;
			line-height: 1;
			font-weight: 100;
			@extend .mt50;
		}
	}
	
	
	@include media_1300 {
	
	}
	
	@include media_1000 {
		&__text-wrap {
			width: calc(100% / 8 * 6);
			margin-left: calc(100% / 8 * 2);
			
			&::before {
				font-size: 50px;
			}
		}
		
		&__col {
			&_left {
				width: calc(100% / 12 * 8 - 10px);
			}
			
			&_right {
				width: calc(100% / 12 * 4 - 10px);
			}
		}
	}
	
	@include media_700 {
		&__text-wrap {
			width: 100%;
			padding: 0 20px;
			margin-left: 0;
			
			&::before {
				display: none;
			}
		}
		
		&__table {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
		}
		
		&__col {
			width: 100%;
			
			&_right {
				height: 140px;
			}
		}
	}
}

.quotes-slider-text {
	@include slider-preload(&);
	
	&__item {
	
	}
	
	&__content {
		&::after {
			content: '\e916';
			font-family: $icomoon;
			display: inline;
			margin-left: 0.4em;
			vertical-align: middle;
			font-weight: 100;
		}
	}
	
	@include media_min-700 {
		&__content {
			min-height: 72px;
		}
	}
}

.quotes-slider-logo {
	@include slider-preload(&);
	height: 100%;
	
	.slider-dots-nav {
		margin-bottom: 10px;
	}
	
	.slick-list,
	.slick-track,
	&__item,
	&__img-wrap {
		height: 100%;
	}
	
	&__img-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 20px;
	}
	
	&__img {
		display: block;
		max-width: 100%;
		max-height: 100%;
		height: auto;
	}
}

.animate-in-active-slide {
	transition-property: transform, opacity;
	transition-duration: 1s, 1s;
	//transition-duration: .5s, .5s;
	transition-delay: .5s, 0s;
	transition-timing-function: ease-in-out, ease-in-out;
	transform: translateY(20px);
	opacity: 0;
}

.slick-current {
	.animate-in-active-slide {
		transition-duration: 1s, 1s;
		transition-delay: 0s, 0s;
		//transition-delay: .5s, .5s;
		transform: translateY(0);
		opacity: 1;
	}
}