.h1 {
  font-weight: 700;
  font-size: 64px;
  line-height: 78px;
  color: $dark1;

  @include media_1300 {
    font-size: 48px;
    line-height: 59px;
  }

  @include media_1000 {
    font-size: 40px;
    line-height: 49px;
  }

  @include media_700 {
    font-size: 26px;
    line-height: 32px;
  }
}

.h2 {
  font-weight: 700;
  font-size: 45px;
  line-height: 55px;
  color: $dark1;

  @include media_1300 {
    font-size: 42px;
    line-height: 51px;
  }

  @include media_1000 {
    font-size: 36px;
    line-height: 44px;
  }

  @include media_700 {
    font-size: 24px;
    line-height: 29px;
  }
}

.h3 {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: $dark1;

  @include media_1300 {
    font-size: 28px;
    line-height: 34px;
  }

  @include media_1000 {
    font-size: 24px;
    line-height: 29px;
  }

  @include media_700 {
    font-size: 20px;
    line-height: 24px;
  }
}

.h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: $dark1;

  @include media_700 {
    font-size: 14px;
    line-height: 17px;
  }
}

.h5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: $dark1;

  @include media_700 {
    font-size: 14px;
    line-height: 17px;
  }
}

.h6 {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: $dark1;
}

.subtitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: $dark1;

  @include media_1000 {
    font-size: 22px;
    line-height: 36px;
  }

  @include media_700 {
    font-size: 16px;
    line-height: 30px;
  }
}

.subtitle_bold {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: $dark1;

  @include media_1000 {
    font-size: 22px;
    line-height: 36px;
  }

  @include media_700 {
    font-size: 16px;
    line-height: 30px;
  }
}

.body-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;

  @include media_1000 {
    font-size: 17px;
    line-height: 27px;
  }

  @include media_700 {
    font-size: 14px;
    line-height: 20px;
  }
}

.body-text_bold {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;

  @include media_1000 {
    font-size: 17px;
    line-height: 27px;
  }

  @include media_700 {
    font-size: 14px;
    line-height: 20px;
  }
}

.table-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  @include media_1000 {
    font-size: 15px;
    line-height: 24px;
  }

  @include media_700 {
    font-size: 12px;
    line-height: 20px;
  }
}

.table-text_bold {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  @include media_1000 {
    font-size: 15px;
    line-height: 24px;
  }

  @include media_700 {
    font-size: 12px;
    line-height: 20px;
  }
}

@mixin small-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  @include media_700 {
    font-size: 12px;
    line-height: 20px;
  }
}

@mixin extra-small-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;

  @include media_700 {
    font-size: 10px;
    line-height: 20px;
  }
}

.small-text {
  @include small-text;
}

.extra-small-text {
  @include extra-small-text
}

.small-text_bold {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;

  @include media_700 {
    font-size: 12px;
    line-height: 20px;
  }
}

.tiny-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  @include media_700 {
    font-size: 10px;
    line-height: 14px;
  }
}

.tiny-text_bold {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;

  @include media_700 {
    font-size: 10px;
    line-height: 18px;
  }
}

.very-tiny {
  font-family: $montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 1;
}

.very-tiny_space {
  font-family: $montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 14px;
}

.button0 {
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;

  @include media_700 {
    font-size: 18px;
    line-height: 22px;
  }
}

.button1 {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

.button2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.schild {
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  @include media_700 {
    font-size: 7px;
  }
}

.schild1920 {
  font-weight: 700;
  font-size: 10px;
  line-height: 21px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.schild_2 {
  font-weight: 700;
  font-size: 9px;
  line-height: 21px;
  letter-spacing: 0.15em;
  text-transform: uppercase;

  @include media_700 {
    font-size: 7px;
    letter-spacing: 0.2em;
  }
}

.link-dark {
  color: $dark1;
  transition: $default-color-transition;
  cursor: pointer;

  &:hover {
    color: $turquoise;
  }
}

.link-purple {
  color: $turquoise;
  transition: $default-color-transition;
  cursor: pointer;

  &:hover {
    color: $blue;
  }
}

.link-purple-lb {
  color: $turquoise;
  transition: $default-color-transition;
  cursor: pointer;

  &:hover {
    color: $blue2;
  }
}

.link-white-fuchsia {
  color: $white;
  transition: $default-color-transition;
  cursor: pointer;

  &:hover {
    color: $fuchsia-hover;
  }
}

.link-fuchsia {
  color: $fuchsia;
  transition: $default-color-transition;
  cursor: pointer;

  &:hover {
    color: $fuchsia-hover;
  }
}

.link-turquoise {
  color: $turquoise;
  transition: $default-color-transition;
  cursor: pointer;

  &:hover {
    color: $blue;
  }
}

.link-light-gray {
  color: $gray1;
  transition: $default-color-transition;
  cursor: pointer;

  &:hover,
  &._current {
    color: $dark1;
  }

  &._current {
    font-weight: 700;
  }
}

.link-gray {
  color: $gray0;
  transition: $default-color-transition;
  cursor: pointer;

  &:hover,
  &._current {
    color: $dark1;
  }

  &._current {
    font-weight: 700;
  }
}

.link-underline {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

textarea {
  resize: none;
}

b,
strong,
.bold {
  font-weight: 700 !important;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-30 {
  font-size: 30px;
}