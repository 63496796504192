.at-resp-share-element {
	.at-share-btn-elements {
		position: relative;
		margin-top: -5px;

		.at-share-btn {
			margin: 5px 10px 0 0;
		}

		.at-label {
			display: inline;
		}
	}
}

.social-buttons {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin-top: -20px;

	&__label {
		margin-right: 33px;
		color: $dark1;
	}

	[class*=addthis_inline_share_toolbox] {
		.at-resp-share-element {
			.at-share-btn-elements {
				margin-top: 0;

				.at-share-btn {
					margin-top: 20px;
					margin-right: 20px;
				}
			}
		}
	}
	[class*=this_inline_share_toolbox] a {
		vertical-align: middle;
		color: #fff;
		background-color: #CB009B;
		height: 35px;
		width: 35px;
		display: inline-block;
		text-align: center;
		line-height: 41px;
		border-radius: 4px;
		font-size: 16px;

	}

	@include media_700 {
		margin-top: -10px;

		&__label {
			margin-top: 10px;
		}

		[class*=addthis_inline_share_toolbox] {
			.at-resp-share-element {
				.at-share-btn-elements {
					.at-share-btn {
						margin-top: 10px;
						margin-right: 10px;
						border-radius: 12px !important;

						.at-icon-wrapper {
							line-height: 28px !important;
							height: 28px !important;
							width: 28px !important;

							.at-icon {
								width: 28px !important;
								height: 28px !important;
							}
						}
					}
				}
			}
		}
	}
}
