$montserrat: 'montserrat';
$icomoon: 'icomoon';
@charset "UTF-8";

@font-face {
    font-family: $montserrat;
    src:
            url("../../fonts/montserrat-bold-webfont.woff2") format("woff2"),
            url("../../fonts/montserrat-bold-webfont.woff") format("woff"),
            url("../../fonts/montserrat-bold-webfont.ttf") format("truetype");

    font-weight: 700;
    font-style: normal;
    font-display: block;
}


@font-face {
    font-family: $montserrat;
    src:
            url("../../fonts/montserrat-medium-webfont.woff2") format("woff2"),
            url("../../fonts/montserrat-medium-webfont.woff") format("woff"),
            url("../../fonts/montserrat-medium-webfont.ttf") format("truetype");

    font-weight: 500;
    font-style: normal;
    font-display: block;
}


@font-face {
  font-family: $icomoon;
  src:  url('../../fonts/icomoon.eot?8qhyn0');
  src:  url('../../fonts/icomoon.eot?8qhyn0#iefix') format('embedded-opentype'),
  url('../../fonts/icomoon.ttf?8qhyn0') format('truetype'),
  url('../../fonts/icomoon.woff?8qhyn0') format('woff'),
  url('../../fonts/icomoon.svg?8qhyn0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}


[class^="icon-"], [class*=" icon-"] {
  &::before {
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.icon-angle-down:before {
  content: "\62";
}
.icon-angle-left:before {
  content: "\63";
}
.icon-angle-right:before {
  content: "\64";
}
.icon-angle-right-thin:before {
  content: "\65";
}
.icon-arrow-down:before {
  content: "\66";
}
.icon-arrow-left:before {
  content: "\67";
}
.icon-arrow-right:before {
  content: "\68";
}
.icon-bus:before {
  content: "\69";
}
.icon-check:before {
  content: "\6a";
}
.icon-climate:before {
  content: "\6b";
}
.icon-compare-frame:before {
  content: "\6c";
}
.icon-environment:before {
  content: "\6d";
}
.icon-facebook:before {
  content: "\6e";
}
.icon-facebook-frame:before {
  content: "\6f";
}
.icon-heart:before {
  content: "\70";
}
.icon-heart-frame:before {
  content: "\71";
}
.icon-iibs:before {
  content: "\72";
}
.icon-info:before {
  content: "\73";
}
.icon-linkedin:before {
  content: "\74";
}
.icon-linkedin-frame:before {
  content: "\75";
}
.icon-map:before {
  content: "\76";
}
.icon-mls:before {
  content: "\77";
}
.icon-mls-min:before {
  content: "\78";
}
.icon-noise:before {
  content: "\79";
}
.icon-pinterest:before {
  content: "\7a";
}
.icon-pinterest-frame:before {
  content: "\41";
}
.icon-print-frame:before {
  content: "\42";
}
.icon-private-schools:before {
  content: "\43";
}
.icon-public-schools:before {
  content: "\44";
}
.icon-search:before {
  content: "\45";
}
.icon-share-frame:before {
  content: "\46";
}
.icon-twitter:before {
  content: "\47";
}
.icon-twitter-frame:before {
  content: "\48";
}
.icon-youtube:before {
  content: "\49";
}
.icon-youtube-frame:before {
  content: "\4a";
}
.icon-cross:before {
  content: "\4b";
}
.icon-cross-frame:before {
  content: "\4c";
}
.icon-angle-down-frame:before {
  content: "\4d";
}
.icon-arrow-up:before {
  content: "\4e";
}
.icon-dot:before {
  content: "\4f";
}
.icon-heart-bordered:before {
  content: "\50";
}
.icon-heart-frame-bordered:before {
  content: "\51";
}
.icon-arts:before {
  content: "\52";
}
.icon-bicycles:before {
  content: "\53";
}
.icon-cafes:before {
  content: "\54";
}
.icon-driving:before {
  content: "\55";
}
.icon-education:before {
  content: "\56";
}
.icon-fitness:before {
  content: "\57";
}
.icon-groceries:before {
  content: "\58";
}
.icon-marker:before {
  content: "\59";
}
.icon-nightlife:before {
  content: "\5a";
}
.icon-realestate:before {
  content: "\30";
}
.icon-restaurants:before {
  content: "\31";
}
.icon-shopping:before {
  content: "\32";
}
.icon-transit:before {
  content: "\33";
}
.icon-walking:before {
  content: "\34";
}
.icon-quotes:before {
  content: "\35";
}
.icon-email:before {
  content: "\36";
}
.icon-phone:before {
  content: "\37";
}
.icon-point:before {
  content: "\38";
}
.icon-arts-small:before {
  content: "\39";
}
.icon-bicycles-small:before {
  content: "\21";
}
.icon-cafes-small:before {
  content: "\22";
}
.icon-driving-small:before {
  content: "\23";
}
.icon-education-small:before {
  content: "\24";
}
.icon-fitness-small:before {
  content: "\25";
}
.icon-groceries-small:before {
  content: "\26";
}
.icon-nightlife-small:before {
  content: "\27";
}
.icon-realestate-small:before {
  content: "\28";
}
.icon-restaurants-small:before {
  content: "\29";
}
.icon-shopping-small:before {
  content: "\2a";
}
.icon-transit-small:before {
  content: "\2b";
}
.icon-walking-small:before {
  content: "\2c";
}
.icon-advice:before {
  content: "\2d";
}
.icon-details:before {
  content: "\2e";
}
.icon-important:before {
  content: "\2f";
}
.icon-user:before {
  content: "\3a";
}
.icon-angle-up-wide:before {
  content: "\3b";
}
.icon-localservices:before {
  content: "\3c";
}
.icon-pets:before {
  content: "\3d";
}
.icon-active:before {
  content: "\3e";
}
.icon-homeservices:before {
  content: "\3f";
}
.icon-hotelstravel:before {
  content: "\40";
}
.icon-religiousorgs:before {
  content: "\5b";
}
.icon-eventservices:before {
  content: "\5d";
}
.icon-massmedia:before {
  content: "\5e";
}
.icon-publicservicesgovt:before {
  content: "\5f";
}
.icon-localflavor:before {
  content: "\60";
}
.icon-financialservices:before {
  content: "\7b";
}
.icon-auto:before {
  content: "\7c";
}
.icon-health:before {
  content: "\7d";
}
.icon-food:before {
  content: "\7e";
}
.icon-professional:before {
  content: "\5c";
}
.icon-beautysvc:before {
  content: "\e000";
}
.icon-commute-bus-big:before {
  content: "\e001";
}
.icon-commute-car-big:before {
  content: "\e002";
}
.icon-commute-scooter-big:before {
  content: "\e003";
}
.icon-commute-pedestrian-big:before {
  content: "\e004";
}
.icon-commute-bicycle-big:before {
  content: "\e005";
}
.icon-graduation-hat:before {
  content: "\e006";
}
.icon-graduation-hat-frame:before {
  content: "\e007";
}
.icon-align-center:before {
  content: "\e008";
}
.icon-fire:before {
  content: "\61";
}
