.twin-fields {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 260px;

  &::before {
    content: '';
    @include absolute;
    @extend .small-text;
    width: 4px;
    height: 1px;
    background: $gray1;
  }

  .input-style,
  .select-module {
    width: calc(50% - 10px);
  }
}

.add-destination-form {
  width: 100%;
}

.input-button-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  .btn-style {
    margin-left: 5px;
    flex: 0 0 auto;
  }

  .autofill,
  .input-style {
    flex: 1 0 0;
  }

  .autofill {
    &__dropdown {
      width: 300px;
    }
  }
}

.form-style {
  $r: &;
  position: relative;

  &__row {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;

    .btn-style {
      width: 100%;
    }
  }

  &__label {
    width: 100%;
  }

  &__col {
    &._1-2 {
      width: calc(50% - 10px);
    }

    &._1-3 {
      width: calc(100% / 3 - 40px / 3);
    }
  }

  &__label {
    color: $gray1;
  }

  &__buttons-wrap {
    .btn-style {
      margin-right: 5px;
    }
  }

  &__social-buttons-wrap {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: -10px;

    .btn-style {
      width: 100%;
      margin-top: 10px;
    }
  }

  &._disable {
    opacity: .5;
    pointer-events: none;
  }

  @include media_700 {
    &__col {
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      &._1-2,
      &._1-3 {
        width: 100%;
      }
    }

    &__social-buttons-wrap {
      .btn-style {
        width: auto;
        min-width: 45px;
        margin-right: 10px;
      }
    }
  }
}

.confidentiality {
  margin-bottom: 20px;
}

.request-form {
  $r: &;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  .input-style {
    &__input {
      &._textarea {
        height: 194px;
      }
    }
  }

  &__title {
    color: $white;
    width: 100%;
  }

  &__col {
    &_left,
    &_center {
      width: calc(100% / 10 * 3 - 40px / 3);
    }

    &_left {
      z-index: 15;
    }

    &_right {
      width: calc(100% / 10 * 4 - 40px / 3);
    }
  }

  &__row {
    position: relative;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__buttons-wrap {
    text-align: right;

    .btn-style {
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @include media_1300 {
    &__col {
      &_left,
      &_center {
        width: calc(50% - 10px);
      }

      &_right {
        width: 100%;
        margin-top: 30px;
      }
    }
  }

  @include media_1300-1000 {
    &__col {
      &_right {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        #{$r}__buttons-wrap {
          flex: 0 0 auto;
          padding-left: 50px;
        }
      }
    }
  }

  @include media_1000 {
    .input-style {
      &__input {
        &._textarea {
          height: 124px;
        }
      }
    }

    &__col {
      &_left,
      &_center {
        width: 100%;
      }

      &_center {
        margin-top: 10px;
      }
    }

    &__buttons-wrap {
      width: calc(100% + 10px);
      text-align: left;
    }
  }
}

.contact-form {
  $r: &;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  &__col {
    width: 100%;
  }

  &__row {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &._2-col {
    #{$r}__col {
      width: calc(50% - 10px);
    }
  }

  &._collapse {
    #{$r}__check-wrap {
      display: none;
    }
  }

  @include media_1000 {
    &._2-col {
      #{$r}__col {
        width: 100%;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  @include media_700 {
    &._2-col {
      #{$r}__col {
        &_left {
          margin-bottom: 15px;
        }
      }
    }
  }
}

.tel-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;

  &__phone {
    width: 100%;
  }

  @include media_500 {
    &__code {
      width: 100px;
    }

    &__phone {
      width: 100%;
    }
  }
}

.merge-inputs {
  .input-style {
    &:not(:last-child) {
      .input-style__input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
      }
    }

    &:not(:first-child) {
      .input-style__input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.mini-subscribe-form {
  position: relative;

  .input-style {
    z-index: 1;
  }

  .icon-btn {
    @include absolute(0, 5px, 0, auto);
    z-index: 2;
  }
}

.filter-more {
  width: 305px;
  overflow: hidden;

  &__row {
    position: relative;
    width: 100%;

    .list + .list {
      @extend .mt10;
      @extend .pt10;
      border-top: 1px solid $gray-line;
    }

    .twin-fields {
      width: 100%;
    }
  }

  &__label {
    display: block;
    color: $dark1;
  }

  &__button-wrap {
    .btn-style {
      width: 100%;
    }
  }
}